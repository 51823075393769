import { TranslationMessages } from 'react-admin';
import englishMessages from 'ra-language-english';

const customEnglishMessages = {
  ...englishMessages,
  goToList: 'Back to list',
  BACKEND_VALIDATION_ERROR: 'Form is not valid',
  CAN_NOT_SIGN_FILE_FOR_UPLOADING_ERROR: 'File uploading is failed',
  CAN_NOT_GET_ACCESS_TO_S3_SERVICE_ERROR: 'File uploading is failed',
  PICKING_ORDER_CANNOT_BE_UPDATED: 'This order cannot be updated',
  backendValidationErrors: {
    range: '%{property} value should be more then %{min}, but less then %{max}',
    'value_error.missing': '%{property} value is required',
    PROMOCODE_SHOULD_HAVE_DIFFERENT_CODE: 'Promocode name should be unique',
  },
  backendFileErrors: {
    UNPROCESSABLE_FILE_ERROR: 'File is unprocessable',
  },
  uploadedContentErrors: {
    PRODUCT_EXTERNAL_NOT_FOUND:
      'Product not found. Please check product availability and try again',
  },
  ra: {
    ...englishMessages.ra,
    message: {
      ...englishMessages.ra.message,
      delete_title: 'Please, confirm the action',
      delete_account: 'Are you sure you want to delete this account?',
      created_success: 'Successfully created',
      updated_success: 'Successfully updated',
      deleted_success: 'Successfully deleted',
      creation_failure: 'Creation fails',
      update_failure: 'Update fails',
      deleting_failure: 'Delete fails',
    },
    action: {
      ...englishMessages.ra.action,
      select_columns: '',
      checkUncheck: 'Check / Uncheck all',
      exporting: 'Exporting...',
      exported: 'Exported',
      import: 'Import',
      importing: 'Importing...',
      imported: 'Imported',
      upload: 'Upload',
      exampleDownload: 'Download an example',
      exampleLoaded: 'Example is loaded',
      exampleIsLoading: 'Example is loading',
      redirectToLogs: 'Redirect to logs',
    },
    validation: {
      ...englishMessages.ra.validation,
      password:
        'Must be %{min} characters at least and must contain 1 number character, 1 uppercase letter, 1 lowercase letter, 1 special character',
      passwordConfirm: 'Must match Password',
      phone: 'Must be 10 to 15 digits, special characters are optional',
      onlyLatin: 'Only latin characters are allowed',
      onlyLettersAndDigits: 'Only letters and digits are allowed',
      maxLengthExt:
        'Must be %{max} characters or less, you typed in %{cur} characters',
      minLengthExt: 'Must be at least %{min}, you typed in %{cur} characters',
      integer: 'Must be an integer',
      priceTooLow: 'Price must be at least %{min}',
      notZero: 'Must not be zero',
      withoutSpaces: 'Must not contain spaces',
      noCourierAndDispatcherSameTime:
        'User may not have the "Courier" and "Dispatcher" roles at the same time',
      validTimeRange: '%{startTime} must be less then %{endTime}',
      maxDigits: `Number of digits, must not exceed %{max}`,
      invalidCutOffTime: 'Cut-off time should be %{time} or earlier',
      shouldBeEmptyRelatedToField: `This field cannot be used while using %{targetField}`,
      landscapeRequired: 'Only landscape images are allowed',
      invalidAspectRatio:
        'The aspect ratio of the image is incorrect. The width of the selected image is %{width}px. This image requires approximately %{height}px of height.',
      imageLoadError: 'Failed to load the image',
    },
  },
  fileInputRejectedTypes: 'There only next type available: %{types}',
  fileInputMaxSize: 'Max size %{size}, %{rows} rows',
  fileInputRejectedBigSize: 'File is larger than %{bytes} bytes',
  week: {
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday',
  },
  weekAbbreviated: {
    monday: 'Mon',
    tuesday: 'Tues',
    wednesday: 'Wed',
    thursday: 'Thurs',
    friday: 'Fri',
    saturday: 'Sat',
    sunday: 'Sun',
  },
  imageGallery: {
    image: 'Main image',
    additionalImage: 'Additional image',
    allowedImage: 'Allowed types: %{types}. Max file size: %{size}MB',
  },
  pages: {
    login: {
      byPhone: 'By phone',
      byEmail: 'By email',
      byLogin: 'By login',
      signin: 'Enter',
      getCode: 'Get the code',
      username: 'Username',
      login: 'Login',
      email: 'Email',
      phoneNumber: 'Phone number',
      password: 'Password',
      remember: 'Remember me',
      forgotPassword: 'Forgot password?',
      enterLogin: 'Enter your login and password to log in',
      enterEmail: 'Enter your email and password to log in',
      enterPhone: 'Enter your phone number to log in',
      enterCode: 'Enter the code sent to you via SMS',
      resendCodeCountdown: 'You can send the code again in %{time} seconds',
      resendCode: 'Resend code',
      changeNumber: 'Change number ',
      enterCodeDescription1: 'The code was sent to ',
      enterCodeDescription2: ', look at the phone, and enter the code here:',
      noAccount: 'Don’t have an account?',
      prodPhoneValidationError:
        'Value must be a valid phone number beginning with a plus country code',
      error: {
        invalidPhoneNumber: 'Invalid phone number',
        invalidEmail: 'Invalid email',
        failedSendOtpCode: 'Failed to send verification code',
      },
    },
  },
  pos: {
    search: 'Search',
    configuration: 'Configuration',
    language: 'Language',
    theme: {
      name: 'Theme',
      light: 'Light',
      dark: 'Dark',
    },
    dashboard: {
      monthly_revenue: 'Monthly Revenue',
      month_history: '30 Day Revenue History',
      new_orders: 'New Orders',
      pending_reviews: 'Pending Reviews',
      all_reviews: 'See all reviews',
      new_customers: 'New Customers',
      all_customers: 'See all customers',
      pending_orders: 'Pending Orders',
      order: {
        items:
          'by %{customer_name}, one item |||| by %{customer_name}, %{nb_items} items',
      },
      welcome: {
        title: 'Welcome to the react-admin e-commerce demo',
        subtitle:
          "This is the admin of an imaginary poster shop. Feel free to explore and modify the data - it's local to your computer, and will reset each time you reload.",
        ra_button: 'react-admin site',
        demo_button: 'Source for this demo',
      },
    },
    menu: {
      sales: 'Sales',
      catalog: 'Catalog',
      customers: 'Customers',
    },
  },
  resources: {
    customers: {
      name: 'Customer |||| Customers',
      fields: {
        commands: 'Orders',
        first_seen: 'First seen',
        groups: 'Segments',
        last_seen: 'Last seen',
        last_seen_gte: 'Visited Since',
        name: 'Name',
        total_spent: 'Total spent',
        password: 'Password',
        confirm_password: 'Confirm password',
        stateAbbr: 'State',
      },
      filters: {
        last_visited: 'Last visited',
        today: 'Today',
        this_week: 'This week',
        last_week: 'Last week',
        this_month: 'This month',
        last_month: 'Last month',
        earlier: 'Earlier',
        has_ordered: 'Has ordered',
        has_newsletter: 'Has newsletter',
        group: 'Segment',
      },
      fieldGroups: {
        identity: 'Identity',
        address: 'Address',
        stats: 'Stats',
        history: 'History',
        password: 'Password',
        change_password: 'Change Password',
      },
      page: {
        delete: 'Delete Customer',
      },
      errors: {
        password_mismatch:
          'The password confirmation is not the same as the password.',
      },
    },
    commands: {
      name: 'Order |||| Orders',
      amount: '1 order |||| %{smart_count} orders',
      title: 'Order %{reference}',
      fields: {
        basket: {
          delivery: 'Delivery',
          reference: 'Reference',
          quantity: 'Quantity',
          sum: 'Sum',
          tax_rate: 'Tax Rate',
          taxes: 'Tax',
          total: 'Total',
          unit_price: 'Unit Price',
        },
        address: 'Address',
        customer_id: 'Customer',
        date_gte: 'Passed Since',
        date_lte: 'Passed Before',
        nb_items: 'Nb Items',
        total_gte: 'Min amount',
        status: 'Status',
        returned: 'Returned',
      },
      section: {
        order: 'Order',
        customer: 'Customer',
        shipping_address: 'Shipping Address',
        items: 'Items',
        total: 'Totals',
      },
    },
    invoices: {
      name: 'Invoice |||| Invoices',
      fields: {
        date: 'Invoice date',
        customer_id: 'Customer',
        command_id: 'Order',
        date_gte: 'Passed Since',
        date_lte: 'Passed Before',
        total_gte: 'Min amount',
        address: 'Address',
      },
    },
    products: {
      name: 'Poster |||| Posters',
      fields: {
        category_id: 'Category',
        height_gte: 'Min height',
        height_lte: 'Max height',
        height: 'Height',
        image: 'Image',
        price: 'Price',
        reference: 'Reference',
        sales: 'Sales',
        stock_lte: 'Low Stock',
        stock: 'Stock',
        thumbnail: 'Thumbnail',
        width_gte: 'Min width',
        width_lte: 'Max width',
        width: 'Width',
      },
      tabs: {
        image: 'Image',
        details: 'Details',
        description: 'Description',
        reviews: 'Reviews',
      },
      filters: {
        categories: 'Categories',
        stock: 'Stock',
        no_stock: 'Out of stock',
        low_stock: '1 - 9 items',
        average_stock: '10 - 49 items',
        enough_stock: '50 items & more',
        sales: 'Sales',
        best_sellers: 'Best sellers',
        average_sellers: 'Average',
        low_sellers: 'Low',
        never_sold: 'Never sold',
      },
    },
    categories: {
      name: 'Category |||| Categories',
      fields: {
        products: 'Products',
      },
    },
    reviews: {
      name: 'Review |||| Reviews',
      amount: '1 review |||| %{smart_count} reviews',
      relative_to_poster: 'Review on poster',
      detail: 'Review detail',
      fields: {
        customer_id: 'Customer',
        command_id: 'Order',
        product_id: 'Product',
        date_gte: 'Posted since',
        date_lte: 'Posted before',
        date: 'Date',
        comment: 'Comment',
        rating: 'Rating',
      },
      action: {
        accept: 'Accept',
        reject: 'Reject',
      },
      notification: {
        approved_success: 'Review approved',
        approved_error: 'Error: Review not approved',
        rejected_success: 'Review rejected',
        rejected_error: 'Error: Review not rejected',
      },
    },
    segments: {
      name: 'Segment |||| Segments',
      fields: {
        customers: 'Customers',
        name: 'Name',
      },
      data: {
        compulsive: 'Compulsive',
        collector: 'Collector',
        ordered_once: 'Ordered once',
        regular: 'Regular',
        returns: 'Returns',
        reviewer: 'Reviewer',
      },
    },
  },
} satisfies TranslationMessages;

export default customEnglishMessages;
