import { useCallback, useMemo } from 'react';
import { useGetList, useLocaleState } from 'react-admin';

import { ResourceRoutes } from '@Plugins/resourceRoutes';
import { PER_PAGE_VALUE } from '@Widgets/ResourceNestedList/constants';
import { RTL_SUPPORT_LANGUAGES } from '@ROOT/layout/themeProvider';

export interface NestedCategory extends CoreCategory {
  groupName?: string;
}

export const useGroupedCategoryList = (): NestedCategory[] => {
  const { data: categoriesAPI = [] } = useGetList<CoreCategory>(
    ResourceRoutes.catalog.resourcePath,
    {
      pagination: {
        page: 1,
        perPage: PER_PAGE_VALUE,
      },
      sort: {
        field: 'sorting',
        order: 'ASC',
      },
    }
  );
  const [locale] = useLocaleState();
  const isRTL = RTL_SUPPORT_LANGUAGES.includes(locale);

  const groupChildren = useCallback(
    (
      list: CoreCategory[],
      id: CoreCategory['id'],
      rootName: string
    ): NestedCategory[] => {
      const currentLevelRecords = list.filter(
        ({ parentId }) => parentId === id
      );
      const otherLevelRecords = list.filter(({ parentId }) => parentId !== id);

      return currentLevelRecords
        .map(({ id, name, nameAr }) => {
          const localizedName = isRTL && nameAr ? nameAr : name;
          const names = [rootName, localizedName];
          const groupName = isRTL
            ? names.reverse().join(' \\ ')
            : names.join(' / ');

          return otherLevelRecords
            .filter((record) => record.parentId === id)
            .map((item) => {
              const localizedName =
                isRTL && item.nameAr ? item.nameAr : item.name;

              return {
                ...item,
                name: localizedName,
                groupName,
              };
            });
        })
        .filter((item) => !!item.length)
        .flat();
    },
    [isRTL]
  );

  return useMemo(() => {
    const firstLevelCategories = categoriesAPI.filter(
      ({ parentId }) => parentId === undefined || parentId === null
    );
    const otherLevelCategories = categoriesAPI.filter(
      ({ parentId }) => !!parentId
    );

    return firstLevelCategories
      .map(({ id, name, nameAr }) => {
        const localizedName = isRTL && nameAr ? nameAr : name;

        return groupChildren(otherLevelCategories, id, localizedName);
      })
      .flat();
  }, [JSON.stringify(categoriesAPI), groupChildren]);
};
