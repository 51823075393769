import { PluginName, PluginMenu } from '@PluginManager/interface';
import { ReactNode } from 'react';

export interface AccessPermission {
  accessToken: string;
  userId: Nullable<string>;
  rolesList: RoleName[];
  disallowPages: string[];
  allowedPages: string[];
  isSuperadmin: boolean;
  isAdmin: boolean;
  companyId: Nullable<string>;
  companyName: Nullable<CompanyName>;
  company: Nullable<ICompany>;
  parseAccessToken: () => void;
  getPluginList: () => PluginName[];
  checkPages: (menuList: PluginMenu[]) => PluginMenu[];
  checkAccess: (resource: string) => boolean;
  checkPermission: (plugin: PluginName, action: string) => boolean;
  checkRolesExists: (...roles: RoleName[]) => boolean;
}

export interface ICompany {
  isWarehouseWithoutOdoo: boolean;
  isPropertyCreationAvailable: boolean;
  isAdvancedCategory: boolean;
  isMultiBuyEnabled: boolean;
  hasExpressDeliverySettings: boolean;
  hasGTINField: boolean;
  categoryCannotBeWithoutImage: boolean;
  isBackorderEnabled: boolean;
  hasBadgePropertyType: boolean;
  languageChangingAvailable: boolean;
  isServiceFeeDisabled: boolean;
  isServiceFeeThresholdDisabled: boolean;
  isManualStatusChangeEnabled: boolean;
  manualStatusChangeButtonTranslatePath: string;
  isOrderDetailsShownWhileManualStatusChanging: boolean;
  availableStatusesForManualChange: string[];
  isCustomerOrderIdHidden: boolean;
  slotMaxFutureDate: number;
  isIncludesIn: (...companyNames: CompanyName[]) => boolean;
}

export type RoleName =
  | 'superadmin'
  | 'admin'
  | 'customer_support_specialist'
  | 'warehouse_manager'
  | 'procurement_specialist'
  | 'marketer'
  | 'replenishment_specialist';

export type RolesRules = Record<Exclude<RoleName, 'superadmin'>, PluginsRules>;

export type PluginsRules = Partial<Record<PluginName, PluginRules>>;

interface PluginRules {
  permission: string;
  disallowPages: string[];
  disallowActions: string[];
}

export interface Company {
  [key: string]: CompanyRules;
}

interface CompanyRules {
  name: CompanyName;
  disallowPlugins: PluginName[];
  disallowPages: string[];
}

export enum CompanyName {
  TheCloudRetail = 'The Cloud Retail',
  Jiffy = 'Jiffy',
  Baqal = 'Baqal',
  Swifft = 'Swifft',
  Localee = 'Localee',
  CityDrinks = 'City Drinks',
  CircleK = 'CircleK',
  ViloGelato = 'ViloGelato',
  OurKids = 'OurKids',
}

export type CompanyLogo = Partial<Record<CompanyName, ReactNode>>;
