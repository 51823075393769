import { useState, useEffect } from 'react';
import {
  TextInput,
  SelectInput,
  useTranslate,
  useGetList,
  required,
} from 'react-admin';
import { useController } from 'react-hook-form';

import {
  ResourceSwitcher,
  Flex,
  RowInfoBlock,
  ResourceInputNumber,
  ResourceAutocompleteArrayInput,
  ResourceMoneyInput,
} from '@UI';
import { ResourceRoutes } from '@PluginManager/plugins/resourceRoutes';
import {
  isEmptyAfterTrimmed,
  onlyLatin,
  minLengthExt,
  maxLengthExt,
  minNum,
  accessPermission,
} from '@Helpers';
import { useIsMobile } from '@Hooks/useIsMobile';

import { DeliveryMechanicSettingsAccordions } from '../components/DeliveryMechanicSettingsAccordions';
import { SlotDeliveryMechanicSettings } from '../components/SlotDeliveryMechanicSettings';

import { ShippingMultiplier } from './ShippingMultiplier';
import { PAYMENT_METHODS_CHOICES } from './constants';

const checkDublicates =
  () =>
  (
    value: string,
    { existingAreas }: { existingAreas: CoreDeliveryAreaEntity[] }
  ) => {
    return existingAreas.some((area) => area.name === value)
      ? 'stores.warehouses.pages.tabs.deliveryArea.errors.nameDuplicate'
      : undefined;
  };
const validateName = [
  required(),
  isEmptyAfterTrimmed(),
  onlyLatin(),
  minLengthExt(3),
  maxLengthExt(250),
  checkDublicates(),
];
const validatePrice = [required(), minNum(0)];
const validateEtaFixedMin = (
  value: string,
  { eta_fixed }: { eta_fixed: boolean }
) => {
  if (!eta_fixed) return undefined;

  if (!value) return 'ra.validation.required';

  return minNum(1)(value);
};
const validateEtaFixedMax = (
  value: string,
  { eta_fixed, eta_fixed_min }: { eta_fixed: boolean; eta_fixed_min: string }
) => {
  if (!eta_fixed) return undefined;

  if (!value) return 'ra.validation.required';

  return minNum(parseInt(eta_fixed_min, 10) + 1)(value);
};

export const GeneralTab = () => {
  const isMobile = useIsMobile();
  const {
    field: { value: fieldETAFixedVal },
  } = useController({ name: 'eta_fixed' });
  const {
    field: { value: fieldDeliveryPriceFixedVal },
  } = useController({ name: 'delivery_price_fixed' });
  const {
    field: { value: fieldDeliveryPriceThresholdFixedVal },
  } = useController({ name: 'delivery_price_threshold_fixed' });
  const {
    field: { value: shippingMethodId },
  } = useController({ name: 'shipping_method_id' });

  const translate = useTranslate();
  const [options, setOptions] = useState<SelectOption[]>([]);
  const { data: shippingMethodList } = useGetList<CoreShippingMethod>(
    ResourceRoutes.shippingMethod.resourcePath
  );

  useEffect(() => {
    if (!shippingMethodList?.length) return;
    setOptions(
      shippingMethodList
        .filter(({ is_active }) => is_active)
        .map(({ id, code }) => ({ name: code, id }))
    );
  }, [JSON.stringify(shippingMethodList)]);

  return (
    <>
      <RowInfoBlock
        asColumn
        flex={1}
        justifyContent="flex-start"
        sx={{ mt: 2 }}
      >
        <ResourceSwitcher
          name="active"
          labelKey="stores.warehouses.pages.tabs.deliveryArea.drawer.label.active"
        />

        <Flex asColumn={isMobile} gap={1} fullWidth>
          <TextInput
            source="name"
            name="name"
            label="stores.warehouses.pages.tabs.deliveryArea.drawer.label.name"
            fullWidth
            validate={validateName}
          />

          <SelectInput
            source="shipping_method_id"
            name="shipping_method_id"
            label="stores.warehouses.pages.tabs.deliveryArea.drawer.label.shippingMethod"
            choices={options}
            fullWidth
            validate={required()}
          />
        </Flex>

        <ResourceAutocompleteArrayInput
          source="payment_methods"
          name="payment_methods"
          label="stores.warehouses.pages.tabs.deliveryArea.drawer.label.paymentMethods"
          choices={PAYMENT_METHODS_CHOICES}
          fullWidth
          validate={required()}
        />

        <ResourceSwitcher
          name="eta_fixed"
          labelKey="stores.warehouses.pages.tabs.deliveryArea.drawer.label.etaFixed"
        />

        {fieldETAFixedVal && (
          <Flex asColumn={isMobile} gap={1} fullWidth>
            <ResourceInputNumber
              source="eta_fixed_min"
              name="eta_fixed_min"
              label="stores.warehouses.pages.tabs.deliveryArea.drawer.label.etaFixedMin"
              validate={validateEtaFixedMin}
              isRequired
              isInteger
              fullWidth
            />

            <ResourceInputNumber
              source="eta_fixed_max"
              name="eta_fixed_max"
              label="stores.warehouses.pages.tabs.deliveryArea.drawer.label.etaFixedMax"
              validate={validateEtaFixedMax}
              isRequired
              isInteger
              fullWidth
            />
          </Flex>
        )}
      </RowInfoBlock>

      {accessPermission.company?.hasExpressDeliverySettings && (
        <>
          <DeliveryMechanicSettingsAccordions
            shippingMethodId={shippingMethodId}
          />

          {/* TODO: Move slot delivery settings to the accordion */}
          <SlotDeliveryMechanicSettings />
        </>
      )}

      <RowInfoBlock
        asColumn
        flex={1}
        justifyContent="flex-start"
        title={translate(
          'stores.warehouses.pages.tabs.deliveryArea.drawer.caption.shippingCost.title'
        )}
      >
        <ResourceMoneyInput
          source="delivery_price"
          name="delivery_price"
          label="stores.warehouses.pages.tabs.deliveryArea.drawer.label.deliveryPrice"
          fullWidth
          validate={validatePrice}
        />

        <ResourceSwitcher
          name="delivery_price_fixed"
          labelKey="stores.warehouses.pages.tabs.deliveryArea.drawer.label.deliveryPriceFixed"
          disabled
        />

        {!fieldDeliveryPriceFixedVal && (
          <>
            <ResourceMoneyInput
              source="delivery_price_max"
              name="delivery_price_max"
              label="stores.warehouses.pages.tabs.deliveryArea.drawer.label.deliveryPriceMax"
              fullWidth
              validate={validatePrice}
            />

            <ShippingMultiplier
              titleKey="stores.warehouses.pages.tabs.deliveryArea.drawer.label.shippingCostMultipliers"
              name="delivery_price_multipliers"
              addButtonTitleKey="stores.warehouses.pages.tabs.deliveryArea.buttons.addDeliveryPriceMultiplier"
            />
          </>
        )}
      </RowInfoBlock>

      <RowInfoBlock
        asColumn
        flex={1}
        justifyContent="flex-start"
        title={translate(
          'stores.warehouses.pages.tabs.deliveryArea.drawer.caption.deliveryCost.title'
        )}
      >
        <ResourceMoneyInput
          source="delivery_price_threshold"
          name="delivery_price_threshold"
          label="stores.warehouses.pages.tabs.deliveryArea.drawer.label.basicFreeShippingThreshold"
          fullWidth
          validate={validatePrice}
        />

        <ResourceSwitcher
          name="delivery_price_threshold_fixed"
          labelKey="stores.warehouses.pages.tabs.deliveryArea.drawer.label.fixedBasicFreeShippingThreshold"
          disabled
        />

        {!fieldDeliveryPriceThresholdFixedVal && (
          <ShippingMultiplier
            titleKey="stores.warehouses.pages.tabs.deliveryArea.drawer.label.freeShippingThresholdMultipliers"
            name="delivery_price_threshold_multipliers"
            addButtonTitleKey="stores.warehouses.pages.tabs.deliveryArea.buttons.addDeliveryPriceThresholdMultiplier"
          />
        )}
      </RowInfoBlock>
    </>
  );
};
