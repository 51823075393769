import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useListContext, useResourceContext } from 'react-admin';

import { OfferImportsProps } from './interfaces';
export const RECENT_IMPORT_ID_QUERY_NAME = 'recentImportId';

const RecentOfferImportDetail = ({
  onRowClick,
}: {
  onRowClick?: OfferImportsProps['onRowClick'];
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { data } = useListContext();
  const recentImportId = searchParams.get(RECENT_IMPORT_ID_QUERY_NAME);

  const resource = useResourceContext();

  useEffect(() => {
    if (recentImportId) {
      const targetImport = data?.find((item) => item.id === recentImportId);

      if (targetImport) {
        onRowClick?.(recentImportId, resource, targetImport);

        searchParams.delete(RECENT_IMPORT_ID_QUERY_NAME);
        setSearchParams(searchParams);
      }
    }
  }, [recentImportId, data]);

  return <></>;
};

export default RecentOfferImportDetail;
