import {
  FormTab,
  required,
  SaveButton,
  TextInput,
  Toolbar,
  RadioButtonGroupInput,
  FormDataConsumer,
  useTranslate,
} from 'react-admin';
import { Typography, Alert } from '@mui/material';

import { Flex, ResourceMoneyInput, ResourceTab, ResourceTabbedForm } from '@UI';
import { accessPermission, minNum, pointsToUnits } from '@Helpers';
import { LinkedProduct } from '@Plugins/Catalogue/resources/Offers/components/LinkedProduct';
import { CategorySelect } from '@Plugins/Catalogue/resources/Products/components/CategorySelect';
import { useGroupedCategoryList } from '@Hooks/useGroupedCategoryList';
import { DEFAULT_OFFER_CREATE_FORM_VALUES } from '@Plugins/Catalogue/resources/share/constants';

import { OfferProductReferenceType } from './interfaces';

const CreatePage = () => {
  const translate = useTranslate();
  const groupedCategoryList = useGroupedCategoryList();

  return (
    <>
      <ResourceTabbedForm
        defaultValues={DEFAULT_OFFER_CREATE_FORM_VALUES}
        toolbar={
          accessPermission.checkPermission('Catalogue', 'C') ? (
            <Toolbar sx={{ justifyContent: 'space-between' }}>
              <SaveButton />
            </Toolbar>
          ) : (
            <></>
          )
        }
      >
        <FormTab label="catalogue.pages.products.tabs.general">
          <Flex asColumn gap={1} fullWidth>
            <TextInput
              name="name"
              source="name"
              label="catalogue.pages.offers.labels.name"
              fullWidth
              validate={validateProductName}
            />

            <ResourceMoneyInput
              name="price"
              source="price"
              label="catalogue.pages.offers.labels.price"
              validate={validatePrice}
              fullWidth
            />
          </Flex>
        </FormTab>
        <ResourceTab
          subscribedFields={['referenceMethod']}
          label="catalogue.pages.products.tabs.linkedProduct"
        >
          <RadioButtonGroupInput
            label="catalogue.pages.offers.labels.productReference"
            source="referenceMethod"
            validate={validateProductName}
            choices={[
              {
                id: OfferProductReferenceType.CREATE,
                name: 'catalogue.pages.offers.labels.referenceMethod.create',
              },
              {
                id: OfferProductReferenceType.LINK,
                name: 'catalogue.pages.offers.labels.referenceMethod.link',
              },
            ]}
          />
          <FormDataConsumer>
            {({ formData }) => {
              const { referenceMethod } = formData;

              if (referenceMethod === OfferProductReferenceType.CREATE) {
                return (
                  <>
                    <Alert
                      severity="info"
                      variant="outlined"
                      sx={{ mb: 4, mt: 2 }}
                    >
                      <Typography>
                        {translate(
                          'catalogue.pages.offers.messages.creatingNewOfferWithoutReferencedProduct'
                        )}
                      </Typography>
                    </Alert>

                    <CategorySelect
                      source="categoryId"
                      options={groupedCategoryList}
                      validate={validateProductName}
                    />
                  </>
                );
              }

              if (referenceMethod === OfferProductReferenceType.LINK) {
                return <LinkedProduct validate={validateLinkedProduct} />;
              }

              return null;
            }}
          </FormDataConsumer>
        </ResourceTab>
      </ResourceTabbedForm>
    </>
  );
};

const validateProductName = [required()];
const validatePrice = [required(), minNum(parseFloat(pointsToUnits(1)))];
const validateLinkedProduct = [
  required('catalogue.pages.offers.errors.linkedProductRequired'),
];

export default CreatePage;
