import { useTranslate } from 'react-admin';
import { ListItemDrawer } from '@Widgets/ListItemDrawer/ListItemDrawer';
import { OrderDetails } from '../OrderDetails/OrderDetails';
import { ShowFromContainerStyled } from '@Widgets/styles';
import { ResourceRoutes } from '@Plugins/resourceRoutes';
import { ResourceList } from '@Widgets/ResourceList/ResourceList';
import { LIST_COLUMNS, FILTERS } from './constants';
import { accessPermission } from '@Helpers';

export const ListPage = () => {
  const translate = useTranslate();

  const columns = accessPermission.company?.isCustomerOrderIdHidden
    ? LIST_COLUMNS.filter((item) => item.source !== 'multiple_order_id')
    : LIST_COLUMNS;

  return (
    <>
      <ResourceList<CoreOrder>
        columns={columns}
        showDeleteButton={false}
        exporter={false}
        filters={FILTERS}
        defaultSort={{
          field: 'created_at',
          order: 'DESC',
        }}
        getItemCardCaption={(record) =>
          `${translate('orders.pages.labels.orderId')} ${record.order_id}`
        }
      />

      <ListItemDrawer route={ResourceRoutes.orders.routePath}>
        {({ entity }) =>
          entity ? (
            <ShowFromContainerStyled id={entity} emptyWhileLoading>
              <OrderDetails />
            </ShowFromContainerStyled>
          ) : (
            <div />
          )
        }
      </ListItemDrawer>
    </>
  );
};
