import { useMemo } from 'react';
import { InfoRounded } from '@mui/icons-material';
import { useTranslate } from 'react-admin';
import { Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { Flex } from '@UI';

import { FieldGroupProps } from './interface';

export const RowInfoBlock = ({
  titleKey,
  title,
  children,
  isInline,
  titleTranslateOptions,
  helpContent,
  helpIcon = <InfoRounded fontSize="small" />,
  ...rest
}: FieldGroupProps) => {
  const translate = useTranslate();
  const theme = useTheme();

  const caption = useMemo(() => {
    const helperContent = (
      <>
        {helpContent && (
          <Tooltip title={helpContent} placement="top">
            {helpIcon}
          </Tooltip>
        )}
      </>
    );

    if (!title) {
      if (!titleKey) {
        return undefined;
      }

      return (
        <Flex asColumn={false} gap={2}>
          <Typography variant="caption" mb={1}>
            {`${translate(titleKey, titleTranslateOptions)} `}
          </Typography>

          {helperContent}
        </Flex>
      );
    }

    if (typeof title === 'string') {
      return (
        <Flex asColumn={false} gap={2}>
          <Typography variant="caption" mb={1}>
            {title}
          </Typography>

          {helperContent}
        </Flex>
      );
    }

    return (
      <Flex asColumn={false} gap={2}>
        {title}
        {helperContent}
      </Flex>
    );
  }, [title]);

  return (
    <Flex
      padding={2}
      mb={1}
      alignItems={isInline ? 'center' : 'start'}
      justifyContent={isInline ? 'space-between' : 'flex-start'}
      bgcolor={theme.palette.background.default}
      asColumn={!isInline}
      borderRadius={2}
      flexWrap="wrap"
      {...rest}
    >
      {caption}

      {children}
    </Flex>
  );
};
