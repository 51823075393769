import { RequestAPI } from '@RestApi';

import { WMS_PICKING_PRIORITY_LIST_API } from '../../constants';

import { PickingPriorityRemoteItem } from './interfaces';

export const mutatePickingPriority = async (
  data: PickingPriorityRemoteItem[]
) => {
  return RequestAPI.patch(WMS_PICKING_PRIORITY_LIST_API, {
    batch: data.map((item) => ({
      id: item.categoryId,
      pickingPriority: item.pickingPriority,
      pickingCode: item.pickingCode,
    })),
  });
};

export const mapDataToArrayField = (
  item: CorePickingPriorityItem
): PickingPriorityRemoteItem => ({
  ...item,
  categoryId: item.id,
});
