import { RowInfoBlock } from '@UI';
import { Button, CircularProgress, Typography } from '@mui/material';
import {
  FileField,
  FileInput,
  FormDataConsumer,
  required,
  SaveButton,
  SimpleForm,
  Toolbar,
  useNotify,
  useTranslate,
} from 'react-admin';
import { useState } from 'react';
import { FieldValues } from 'react-hook-form';
import {
  DropzoneOptions,
  ErrorCode,
  FileError,
  FileRejection,
} from 'react-dropzone';
import { Upload } from '@mui/icons-material';
import ThemedDrawer from '@UI/ThemedDrawer/ThemedDrawer';

import { FileImportProps } from './interface';
import { FILE_DEFAULT_MAX_ROWS, FILE_DEFAULT_SIZE } from './constants';
import { formatBytes } from './utils';

export const FileImport = ({
  buttonCaptionKey = 'ra.action.import',
  onFileSubmitted,
  isLoading,
  isSuccess,
  children,
  maxFileRows = FILE_DEFAULT_MAX_ROWS,
  maxSize = FILE_DEFAULT_SIZE,
  ...rest
}: FileImportProps) => {
  const translate = useTranslate();
  const [showExportPopup, setShowExportPopup] = useState(false);
  const notify = useNotify();

  const onCloseDrawerHandle = () => setShowExportPopup(false);

  const onOpenDrawerHandle = () => setShowExportPopup(true);

  const onFileSubmitHandle = (values: FieldValues) => {
    setShowExportPopup(false);
    onFileSubmitted(values.file.rawFile);

    return false;
  };

  const getDropErrorMessageByCode = (code: FileError['code']) => {
    switch (code) {
      case ErrorCode.FileInvalidType:
        return translate('fileInputRejectedTypes', { types: rest.accept });
      case ErrorCode.FileTooLarge:
        return translate('fileInputRejectedBigSize', { bytes: maxSize });
      default:
        return 'null';
    }
  };

  const getDropRejectionMessage = (fileRejections: FileRejection[]) =>
    fileRejections[0].errors
      .map((error) => getDropErrorMessageByCode(error.code))
      .join('\n');

  const onDropRejected: DropzoneOptions['onDropRejected'] = (fileRejections) =>
    notify(getDropRejectionMessage(fileRejections), {
      type: 'error',
      multiLine: true,
    });

  return (
    <>
      <Button
        disabled={isLoading}
        size="small"
        startIcon={isLoading ? <CircularProgress size={16} /> : <Upload />}
        onClick={onOpenDrawerHandle}
      >
        {translate(
          isLoading
            ? 'ra.action.importing'
            : isSuccess
            ? 'ra.action.imported'
            : buttonCaptionKey
        )}
      </Button>

      <ThemedDrawer open={showExportPopup} onClose={onCloseDrawerHandle}>
        <RowInfoBlock p={0} m={0}>
          <SimpleForm
            onSubmit={onFileSubmitHandle}
            toolbar={
              <FormDataConsumer>
                {({ formData }) => {
                  const isNoFile = !formData.file;

                  return (
                    <Toolbar>
                      <SaveButton
                        disabled={isNoFile}
                        label="ra.action.upload"
                        icon={<Upload />}
                      />
                    </Toolbar>
                  );
                }}
              </FormDataConsumer>
            }
          >
            <FileInput
              source="file"
              name="file"
              isRequired
              validate={required()}
              maxSize={maxSize}
              {...rest}
              options={{
                accept: rest.accept,
                onDropRejected,
              }}
            >
              <FileField source="src" title="title" />
            </FileInput>

            {rest.accept?.length && (
              <>
                <Typography variant="caption">
                  {translate('fileInputRejectedTypes', { types: rest.accept })}
                </Typography>
                <Typography variant="caption">
                  {translate('fileInputMaxSize', {
                    size: formatBytes(maxSize),
                    rows: maxFileRows,
                  })}
                </Typography>
              </>
            )}
          </SimpleForm>

          {children}
        </RowInfoBlock>
      </ThemedDrawer>
    </>
  );
};
