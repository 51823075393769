import {
  Datagrid,
  TextField,
  useList,
  ListContextProvider,
  useTranslate,
} from 'react-admin';
import { RaRecord } from 'ra-core';
import { Typography, Box } from '@mui/material';
import { RowInfoBlock, Flex, Loader } from '@UI';

import { StatusBadgeStyled } from './styled';

export const DetailsPage = ({ record }: { record: CoreOfferImport }) => {
  const translate = useTranslate();
  const errors: RaRecord[] = record.errors.map((error, idx) => ({
    id: idx,
    error,
  }));
  const listContext = useList({ data: errors });

  return (
    <Flex asColumn maxWidth={500} width="95vw">
      <Typography variant="h6" mb={1}>
        {translate('catalogue.pages.offerOperations.labels.mobileCardCaption', {
          id: record.id,
        })}
      </Typography>
      <Typography variant="caption" mb={2}>
        {translate('catalogue.pages.offerImports.labels.status')}{' '}
        <StatusBadgeStyled orderStatus={record.status as CoreDCOrderStatus}>
          {translate(`catalogue.pages.offerImports.statuses.${record.status}`)}
        </StatusBadgeStyled>
      </Typography>

      <RowInfoBlock mx={-3}>
        {record.status === 'in_progress' ? (
          <Box sx={{ width: '100%' }}>
            <Loader />
          </Box>
        ) : errors.length > 0 ? (
          <ListContextProvider value={listContext}>
            <Datagrid bulkActionButtons={false} sx={{ width: '100%' }}>
              <TextField
                source="error"
                sortable={false}
                label="catalogue.pages.offerOperations.labels.errors"
              />
            </Datagrid>
          </ListContextProvider>
        ) : (
          <Typography textAlign="center" fontSize={16}>
            {translate('catalogue.pages.offerOperations.labels.noErrors')}
          </Typography>
        )}
      </RowInfoBlock>
    </Flex>
  );
};
