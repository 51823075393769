import { ListColumn } from '@Widgets/ResourceList/interface';

export const offersApiUrl = '/catalog/v1/offers';
export const offersV2ApiUrl = '/catalog/v2/offers';
export const inStockInformationApiUrl = '/stocks/v1';

export enum ResourceMeta {
  inStockInformation = 'inStockInformation',
}

export const IN_STOCK_INFORMATION_LIST_COLUMNS: ListColumn<CoreOffer>[] = [
  {
    source: 'id',
    label: 'catalogue.pages.offers.labels.id',
    type: 'text',
    sortable: false,
  },
  {
    // TODO: fix source in type definition
    // @ts-ignore
    source: 'createdAt',
    type: 'date',
    label: 'catalogue.pages.offers.labels.createdAt',
  },
  {
    // TODO: fix source in type definition
    // @ts-ignore
    source: 'availableQuantity',
    type: 'number',
    label: 'catalogue.pages.offers.labels.availableQuantity',
    sortable: false,
  },
  {
    source: 'name',
    type: 'text',
    label: 'catalogue.pages.offers.labels.name',
    sortable: false,
  },
  {
    // TODO: fix source in type definition
    // @ts-ignore
    source: 'reservedQuantity',
    type: 'number',
    label: 'catalogue.pages.offers.labels.reservedQuantity',
    sortable: false,
  },
  {
    // TODO: fix source in type definition
    // @ts-ignore
    source: 'sellableQuantity',
    type: 'number',
    label: 'catalogue.pages.offers.labels.sellableQuantity',
    sortable: false,
  },
  {
    // TODO: fix source in type definition
    // @ts-ignore
    source: 'sku',
    type: 'text',
    label: 'catalogue.pages.offers.labels.sku',
    sortable: false,
  },
  {
    // TODO: fix source in type definition
    // @ts-ignore
    source: 'updatedAt',
    type: 'date',
    label: 'catalogue.pages.offers.labels.updatedAt',
  },
  {
    // TODO: fix source in type definition
    // @ts-ignore
    source: 'warehouseCode',
    type: 'text',
    label: 'catalogue.pages.offers.labels.warehouseCode',
    sortable: false,
  },
];
