import { ChangeEvent, MouseEvent, useEffect, useState } from 'react';
import {
  DateField,
  useNotify,
  useRecordContext,
  useTranslate,
  useUpdate,
} from 'react-admin';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { Flex, Loader } from '@UI';
import { ResourceRoutes } from '@PluginManager/plugins/resourceRoutes';
import {
  MoneyFormatter,
  pointsToUnits,
  unitsToPoints,
  useSetErrorToErrorsContext,
} from '@Helpers';
import { KYCVerificationStatus } from '@Plugins/Customers/resources/Customers/interfaces';
import { handleInput } from '@UI/ResourceUI/ResourceMoney/utils';

import { RESOURCES_SCHEMA } from '../schemas/resourcesSchema';
import { ClickCollectOrder } from '../interfaces';
import { clickAndCollectTranslationSchema } from '../constants';
import { ConfirmAdultPackageDialog } from '../components/ConfirmAdultPackageDialog';
import { PackageDeliveryType } from './interface';
import { HighlightingRowInfoBlock, StyledAlert } from './styles';

export const DetailsPage = () => {
  const record = useRecordContext<ClickCollectOrder>();
  const translate = useTranslate();
  const notify = useNotify();
  const navigate = useNavigate();
  const { errors, onErrorHandler } = useSetErrorToErrorsContext();

  const [updateDelivery, { isSuccess, isLoading }] = useUpdate();
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const [selectedPackages, setSelectedPackages] = useState<
    PackageDeliveryType[]
  >([]);
  const [amountPromt, setAmountPromt] = useState<string>('');
  const [isConfirmDialogOpen, setIsConfirmOpen] = useState<boolean>(false);
  const [isConfirmCancelDialogOpen, setIsConfirmCancelDialogOpen] =
    useState<boolean>(false);
  const [validationMessage, setValidationMessage] = useState<string>('');

  const labels = clickAndCollectTranslationSchema.labels;
  const isCash = record?.payment_method === 'CASH';
  const orderTotal = record?.actual_paid_price;

  const handlerSubmit = (data: number[]) => {
    const identifier = record.uuid || record.id;

    updateDelivery(
      ResourceRoutes.wmsClickAndCollect.resourcePath,
      {
        id: identifier,
        data,
        previousData: { id: identifier },
      },
      {
        mutationMode: 'optimistic',
        onSuccess: () => {
          if (!data.length) {
            notify(
              translate(
                clickAndCollectTranslationSchema.messages.successfullyCanceled
              ),
              { type: 'success', autoHideDuration: 10000 }
            );

            return;
          }

          notify(
            translate(
              clickAndCollectTranslationSchema.messages.updateDelivery,
              {
                orderId: record.order_id,
              }
            ),
            { type: 'success', autoHideDuration: 10000 }
          );
        },
        onError: onErrorHandler,
      }
    );
  };

  const calculateOrderChange = (): string => {
    if (!amountPromt || isNaN(Number(amountPromt)) || !orderTotal) {
      return '0';
    }

    const amountNum = Number(unitsToPoints(amountPromt));

    if (amountNum < orderTotal) {
      return '0';
    }

    const change = amountNum - orderTotal;
    const formater = new MoneyFormatter(change);

    return formater.unitsAmount;
  };

  const handleCloseConfirmDialog = () => {
    setIsConfirmOpen(false);
  };

  const handleCloseConfirmCancelDialog = () => {
    setIsConfirmCancelDialogOpen(false);
  };

  const handleConfirmHandover = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    setIsConfirmOpen(true);
  };

  const handleFailHandover = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    setIsConfirmCancelDialogOpen(true);
  };

  const handleValidateAmountReceived = (val: string) => {
    if (!val) {
      setValidationMessage('');

      return;
    }

    if (isNaN(Number(val))) {
      setValidationMessage(translate('ra.validation.number'));

      return;
    }

    if (orderTotal && Number(unitsToPoints(Number(val))) < orderTotal) {
      setValidationMessage(
        translate('ra.validation.minValue', {
          min: pointsToUnits(orderTotal),
        })
      );

      return;
    }

    setValidationMessage('');
  };

  const handleChangeAmountReceived = (e: ChangeEvent<HTMLInputElement>) =>
    setAmountPromt(e.target.value);

  useEffect(() => {
    if (!record) return;

    const isCanceled = ['cancelled', 'failed'].includes(record.public_status);
    const isDelivered = record.public_status === 'delivered';
    const errors: string[] = [];

    if (isCanceled) {
      errors.push(translate(clickAndCollectTranslationSchema.errors.canceled));
    }

    if (isDelivered) {
      errors.push(
        translate(clickAndCollectTranslationSchema.errors.alreadyPicked)
      );
    }

    if (
      !isCanceled &&
      !isDelivered &&
      record.public_status !== 'ready_for_pickup'
    ) {
      errors.push(
        translate(clickAndCollectTranslationSchema.errors.incorrectStatus)
      );
    }

    if (record.delivery_method !== 'CLICK_AND_COLLECT') {
      errors.push(
        translate(
          clickAndCollectTranslationSchema.errors.incorrectDeliveryMethod
        )
      );
    }

    setErrorMessages(errors);
  }, [record?.status, record?.delivery_method]);

  useEffect(() => {
    if (!isSuccess) return;
    navigate(ResourceRoutes.wmsClickAndCollect.resourcePath);
  }, [isSuccess]);

  useEffect(() => {
    const resultErrors = Object.values(errors).flat();

    if (resultErrors.length) {
      const errorsText = [
        translate(clickAndCollectTranslationSchema.errors.cantUpdateDelivery),
        ...resultErrors,
      ].join('\n');

      notify(errorsText, {
        type: 'error',
        autoHideDuration: 10000,
        multiLine: true,
      });
    }
  }, [JSON.stringify(errors)]);

  useEffect(() => {
    if (record) {
      if (record.customer_cash && !amountPromt) {
        setAmountPromt(pointsToUnits(record.customer_cash));
      }

      if (isCash) {
        setSelectedPackages(
          record.bag_types as unknown as PackageDeliveryType[]
        );
        handleValidateAmountReceived(amountPromt);
      }
    }
  }, [record?.customer_cash, amountPromt]);

  if (!record) return <Loader />;

  return (
    <Flex asColumn maxWidth={500} width="95vw" height="100vh" padding={1}>
      <Box padding={1}>
        <Typography variant="h5">
          {translate(labels.order, { orderId: record.order_id })}
        </Typography>

        <Typography variant="subtitle2">
          {`${translate(labels.createdAt)}: `}

          <DateField source={RESOURCES_SCHEMA.createdAt} showTime />
        </Typography>
      </Box>

      {errorMessages.length > 0 ? (
        <Typography
          bgcolor="rgba(244, 67, 54, 0.08)"
          color="error"
          fontWeight="bold"
          align="center"
          padding={2}
          fontSize={14}
          marginTop={2}
        >
          {errorMessages.map((error, index) => (
            <Box key={index} marginY={1}>
              {error}
            </Box>
          ))}
        </Typography>
      ) : (
        <>
          <Flex
            asColumn={false}
            justifyContent="space-between"
            flexWrap="wrap"
            sx={{ p: 2 }}
            gap={2}
          >
            <Box sx={{ width: 215, pl: 1 }}>
              <Typography variant="caption">
                {translate(labels.buyerName)}
              </Typography>
              <Typography fontSize={14}>
                {record.recipient?.full_name || translate(labels.unknown)}
              </Typography>
            </Box>
            <Box sx={{ width: 215, pl: 1 }}>
              <Typography variant="caption">
                {translate('wms-picking.pages.click-collect.labels.age')}
              </Typography>
              <Typography fontSize={14}>
                {translate(
                  record.customer?.[0]?.kyc_verification_status ===
                    KYCVerificationStatus.VERIFIED
                    ? 'wms-picking.pages.click-collect.labels.confirmed'
                    : 'wms-picking.pages.click-collect.labels.unconfirmed'
                )}
              </Typography>
            </Box>
            <Box sx={{ pl: 1 }}>
              <Typography variant="caption">
                {translate('wms-picking.pages.click-collect.labels.comments')}
              </Typography>
              <Typography fontSize={14}>
                {record.address?.comment || '-'}
              </Typography>
            </Box>
          </Flex>

          {record.bag_types?.map((type) => {
            const name = translate(
              `wms-picking.pages.click-collect.lists.deliveryTypeName.${
                DELIVERY_TYPE_NAME[type as PackageDeliveryType]
              }`
            );
            const isSelected = selectedPackages.includes(type);

            return (
              <HighlightingRowInfoBlock
                $selected={isSelected}
                key={name}
                fullWidth
                title={
                  <Flex
                    asColumn={false}
                    alignItems="center"
                    justifyContent="space-between"
                    fullWidth
                    sx={{ borderBottom: '1px solid #2F3841' }}
                  >
                    <Typography variant="subtitle1">{name}</Typography>

                    <Checkbox
                      checked={isSelected}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedPackages((prev) => [...prev, type]);

                          return;
                        }

                        setSelectedPackages((prev) =>
                          prev.filter((item) => item !== type)
                        );
                      }}
                      disabled={isCash}
                    />
                  </Flex>
                }
                gap={2}
              >
                {type === PackageDeliveryType.Alcohol && (
                  <StyledAlert
                    variant="filled"
                    severity="error"
                    iconMapping={{
                      error: <Chip label="18 +" color="error" />,
                    }}
                  >
                    <Typography variant="caption" component="p">
                      {translate(
                        'wms-picking.pages.click-collect.messages.adult1'
                      )}
                    </Typography>
                    <Typography variant="caption" component="p">
                      {translate(
                        'wms-picking.pages.click-collect.messages.adult2'
                      )}
                    </Typography>
                  </StyledAlert>
                )}
              </HighlightingRowInfoBlock>
            );
          })}

          {isCash && (
            <Flex
              asColumn={false}
              justifyContent="space-between"
              flexWrap="wrap"
              sx={{ p: 2 }}
              gap={2}
            >
              <Box sx={{ width: 215, pl: 1.5 }}>
                <Typography variant="caption">
                  {translate(
                    'wms-picking.pages.click-collect.labels.amountToPay'
                  )}
                </Typography>
                <Typography fontSize={14}>
                  {new MoneyFormatter(orderTotal).withCurrency}
                </Typography>
              </Box>
              <Box sx={{ width: 215, pl: 1 }}>
                <Typography variant="caption">
                  {translate(
                    'wms-picking.pages.click-collect.labels.paymentType'
                  )}
                </Typography>
                <Typography fontSize={14}>
                  {translate(
                    `wms-picking.pages.click-collect.lists.paymentMethods.${record.payment_method}`
                  )}
                </Typography>
              </Box>
              <TextField
                label={translate(
                  'wms-picking.pages.click-collect.labels.enterAmount'
                )}
                value={amountPromt}
                onChange={handleChangeAmountReceived}
                variant="outlined"
                InputProps={{
                  onInput: handleInput,
                  startAdornment: (
                    <InputAdornment position="start">
                      {MoneyFormatter.getCurrencySymbol()}
                    </InputAdornment>
                  ),
                }}
                error={!!validationMessage}
                helperText={validationMessage}
                sx={{ width: 215 }}
              />
              <Box sx={{ width: 215, pl: 1 }}>
                <Typography variant="caption">
                  {translate(
                    'wms-picking.pages.click-collect.labels.autoChange'
                  )}
                </Typography>
                <Typography fontSize={14}>{calculateOrderChange()}</Typography>
              </Box>
            </Flex>
          )}

          <Flex
            asColumn={false}
            justifyContent="space-between"
            alignItems="center"
            sx={{ mt: 2, px: 2 }}
          >
            <Button
              type="button"
              color="primary"
              variant="contained"
              disabled={!selectedPackages.length || isLoading}
              onClick={handleConfirmHandover}
            >
              {translate('wms-picking.pages.click-collect.buttons.confirm')}
            </Button>
            <Button
              type="button"
              color="error"
              variant="contained"
              disabled={isLoading}
              onClick={handleFailHandover}
            >
              {translate('wms-picking.pages.click-collect.buttons.fail')}
            </Button>
          </Flex>

          <ConfirmAdultPackageDialog
            title="wms-picking.pages.click-collect.messages.confirmAdultTitle"
            open={isConfirmDialogOpen}
            onClose={handleCloseConfirmDialog}
            onConfirm={() => {
              handlerSubmit(selectedPackages);
              handleCloseConfirmDialog();
            }}
          >
            <Typography>
              {translate(
                `wms-picking.pages.click-collect.messages.${
                  selectedPackages.includes(PackageDeliveryType.Alcohol)
                    ? 'confirmAdultMessage'
                    : 'confirmFinish'
                }`
              )}
            </Typography>
          </ConfirmAdultPackageDialog>

          <ConfirmAdultPackageDialog
            title="wms-picking.pages.click-collect.messages.confirmAdultTitle"
            open={isConfirmCancelDialogOpen}
            onClose={handleCloseConfirmCancelDialog}
            onConfirm={() => {
              handlerSubmit([]);
              handleCloseConfirmCancelDialog();
            }}
            confirmBtnText="wms-picking.pages.click-collect.buttons.fail"
          >
            <Typography>
              {translate(
                'wms-picking.pages.click-collect.messages.confirmFailMessage'
              )}
            </Typography>
          </ConfirmAdultPackageDialog>
        </>
      )}
    </Flex>
  );
};

export const DELIVERY_TYPE_NAME: Record<PackageDeliveryType, string> = {
  [PackageDeliveryType.Frozen]: 'Frozen',
  [PackageDeliveryType.OwnKitchen]: 'OwnKitchen',
  [PackageDeliveryType.Alcohol]: 'Alcohol',
  [PackageDeliveryType.Tobacco]: 'Alcohol',
  [PackageDeliveryType.Grocery]: 'Grocery',
  [PackageDeliveryType.Pharma]: 'Grocery',
  [PackageDeliveryType.Chemical]: 'Grocery',
};
