import { useState, SyntheticEvent } from 'react';
import { useTranslate, useNotify } from 'react-admin';
import { useMutation, useQueryClient } from 'react-query';
import { FieldValues } from 'react-hook-form';
import {
  Box,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import Add from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  deliveryScheduleCRUD,
  submitScheduleValues,
} from '@Plugins/Stores/resources/Warehouses/pages/ItemPage/PageForm/tabs/DeliveryAreas/utils';

import { ScheduleIntervalForm } from '../ScheduleIntervalForm';
import { ScheduleHeadStyled, AddScheduleButtonStyled } from './styled';
import { DeliverySlotProps } from './interfaces';
import { sortScheduleSlotsByTime } from './utils';
import { SCHEDULE_ACCORDION_DEFAULT_VALUES } from './constants';
import { accessPermission } from "@Helpers";

const ScheduleIntervalAccordion = ({
  weekDay,
  timezone,
  zoneId,
  scheduleSlots = [],
  localization,
}: DeliverySlotProps) => {
  const {
    emptySchedule = 'emptySchedule',
    addScheduleButton = 'addScheduleButton',
    addNewSchedule = 'addNewSchedule',
    confirmActionModalTitle = 'confirmActionModalTitle',
    confirmActionModalContent = 'confirmActionModalContent',
    confirmButton = 'ra.action.confirm',
    declinedConfirmationButton = 'ra.action.cancel',
    creationSuccess = 'ra.message.created_success',
    creationFailure = 'ra.message.creation_failure',
    updatingSuccess = 'ra.message.updated_success',
    updatingFailure = 'ra.message.update_failure',
    deletingSuccess = 'ra.message.deleted_success',
    deletingFailure = 'ra.message.deleting_failure',
    ...fieldsLocalization
  } = localization || {};

  const t = useTranslate();
  const [isCreateMode, setIsCreateMode] = useState<boolean>(false);
  const [activeAccordion, setActiveAccordion] = useState<string | undefined>();
  const [nominatedToDelete, setNominatedToDelete] = useState<
    string | undefined
  >();

  const notify = useNotify();
  const queryClient = useQueryClient();

  const buildScheduleData = (values: FieldValues): FieldValues => ({
    ...submitScheduleValues(values),
    zone_id: zoneId,
    time_zone: timezone,
  });

  const { mutate: createDeliverySchedule, isLoading: isScheduleCreating } =
    useMutation({
      mutationKey: 'createDeliverySchedule',
      mutationFn: (values: FieldValues) =>
        deliveryScheduleCRUD.create<CoreDeliveryScheduleSlotEntity>({
          data: buildScheduleData(values),
        }),
      onSuccess: async (response) => {
        if (!response) return;

        handleCloseCreateMode();
        await queryClient.invalidateQueries(['deliverySchedule']);
        setActiveAccordion(response.id);
        notify(t(creationSuccess), {
          type: 'success',
          autoHideDuration: 10000,
        });
      },
      onError: (error: Error) => {
        const errorMessage = error?.message ?? t(creationFailure);

        notify(errorMessage, {
          type: 'error',
          autoHideDuration: 10000,
        });
      },
    });
  const { mutate: updateDeliverySchedule, isLoading: isScheduleUpdating } =
    useMutation({
      mutationKey: 'updateDeliverySchedule',
      mutationFn: (data: { id: string; values: FieldValues }) =>
        deliveryScheduleCRUD.update({
          id: data.id,
          data: buildScheduleData(data.values),
        }),
      onSuccess: async (result, variables) => {
        toggleAccordionHandlerFactory(variables.id)(undefined, true);
        await queryClient.invalidateQueries(['deliverySchedule']);
        notify(t(updatingSuccess), {
          type: 'success',
          autoHideDuration: 10000,
        });
      },
      onError: () => {
        notify(t(updatingFailure), {
          type: 'error',
          autoHideDuration: 10000,
        });
      },
    });
  const { mutate: deleteDeliverySchedule, isLoading: isScheduleDeleting } =
    useMutation({
      mutationKey: 'deleteDeliverySchedule',
      mutationFn: (id: string) => deliveryScheduleCRUD.delete({ id }),
      onSuccess: async () => {
        setNominatedToDelete(undefined);
        await queryClient.invalidateQueries(['deliverySchedule']);
        notify(t(deletingSuccess), {
          type: 'success',
          autoHideDuration: 10000,
        });
      },
      onError: () => {
        notify(t(deletingFailure), {
          type: 'error',
          autoHideDuration: 10000,
        });
      },
    });

  const toggleAccordionHandlerFactory =
    (id: string) =>
    (e?: SyntheticEvent, isExpanded = false): void => {
      setActiveAccordion(isExpanded ? id : undefined);
    };

  const updateHandlerFactory =
    (item: CoreDeliveryScheduleSlotEntity) =>
    (values: FieldValues): void => {
      const { order_amount_threshold, ...restValues } = values;

      updateDeliverySchedule({
        id: item.id,
        values: {
          day_of_week: weekDay,
          ...restValues,
          order_amount_threshold: order_amount_threshold || '0',
        },
      });
    };

  const handleCreate = (values: FieldValues): void => {
    createDeliverySchedule({ day_of_week: weekDay, ...values });
  };

  const handleOpenCreateMode = () => {
    setIsCreateMode(true);
  };

  const handleCloseCreateMode = () => {
    setIsCreateMode(false);
  };

  const handleNominateToDelete =
    (item: CoreDeliveryScheduleSlotEntity) => (): void => {
      setNominatedToDelete(item.id);
    };

  const handleDeclineConfirmation = () => {
    setNominatedToDelete(undefined);
  };

  const handleConfirmDeletion = () => {
    if (!nominatedToDelete) {
      return;
    }

    deleteDeliverySchedule(nominatedToDelete);
  };

  return (
    <Box sx={{ pt: 2 }}>
      {scheduleSlots.sort(sortScheduleSlotsByTime).map((item) => (
        <Accordion
          key={item.id}
          expanded={activeAccordion === item.id}
          onChange={toggleAccordionHandlerFactory(item.id)}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <ScheduleHeadStyled>
              <span>
                {item.delivery_start_time} - {item.delivery_end_time}
              </span>
              <span>{item.name}</span>
            </ScheduleHeadStyled>
          </AccordionSummary>
          <AccordionDetails>
            <ScheduleIntervalForm
              record={item}
              onSubmit={updateHandlerFactory(item)}
              onClose={handleNominateToDelete(item)}
              closeButtonText="ra.action.delete"
              localization={fieldsLocalization}
              isLoading={isScheduleUpdating}
            />
          </AccordionDetails>
        </Accordion>
      ))}

      {scheduleSlots.length === 0 && (
        <Typography variant="caption" color="darkgray" fontSize={16}>
          {t(emptySchedule)}
        </Typography>
      )}

      {isCreateMode ? (
        <Accordion expanded>
          <AccordionSummary>{t(addNewSchedule)}</AccordionSummary>
          <AccordionDetails>
            <ScheduleIntervalForm
              record={{
                ...SCHEDULE_ACCORDION_DEFAULT_VALUES,
                max_future_date: accessPermission.company?.slotMaxFutureDate ?? SCHEDULE_ACCORDION_DEFAULT_VALUES.max_future_date,
              }}
              onSubmit={handleCreate}
              onClose={handleCloseCreateMode}
              localization={fieldsLocalization}
              isLoading={isScheduleCreating}
            />
          </AccordionDetails>
        </Accordion>
      ) : (
        <AddScheduleButtonStyled
          type="button"
          variant="contained"
          label={addScheduleButton}
          size="large"
          onClick={handleOpenCreateMode}
          disabled={isScheduleCreating}
        >
          <Add />
        </AddScheduleButtonStyled>
      )}

      <Dialog open={!!nominatedToDelete}>
        <DialogTitle>{t(confirmActionModalTitle)}</DialogTitle>
        <DialogContent>{t(confirmActionModalContent)}</DialogContent>
        <DialogActions>
          <Button
            type="button"
            variant="contained"
            color="error"
            onClick={handleConfirmDeletion}
            disabled={isScheduleDeleting}
          >
            {t(confirmButton)}
          </Button>
          <Button
            type="button"
            variant="text"
            color="primary"
            onClick={handleDeclineConfirmation}
            disabled={isScheduleDeleting}
          >
            {t(declinedConfirmationButton)}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ScheduleIntervalAccordion;
