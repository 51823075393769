import { SyntheticEvent, useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import {
  Pagination,
  SaveButton,
  Toolbar,
  useRecordContext,
  useTranslate,
  useSaveContext,
  useNotify,
} from 'react-admin';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';
import sortBy from 'lodash/sortBy';

import { SNACKBAR_AUTOHIDE_DURATION } from '@MainConstants';
import { RequestAPI } from '@RestApi';
import { ResourceSwitcher, RowInfoBlock } from '@UI';
import { ReorderResourceTable } from '@UI/ReorderResourceTable';

import {
  FormValues,
  SortingProduct,
  SortingProductSavePayload,
} from './interfaces';
import { PER_PAGE_ITEMS, SORTING_PRODUCTS_API_URL, COLUMNS } from './constants';

const SecondLvlCategoryProducts = () => {
  const record = useRecordContext<CoreCategory>();
  const translate = useTranslate();
  const { save } = useSaveContext({});
  const notify = useNotify();

  const API_URL = `${SORTING_PRODUCTS_API_URL}/${record.id}`;

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(PER_PAGE_ITEMS[0]);

  const {
    data: { data = [], pagination: { total = 0 } = {} } = {},
    isLoading,
  } = useQuery({
    queryKey: ['secondLvlCategoryProducts', page, perPage],
    queryFn: async () => {
      const searchParams = new URLSearchParams();

      searchParams.set('page[current]', `${page}`);
      searchParams.set('page[size]', `${perPage}`);

      const response = await RequestAPI.get(
        `${API_URL}?${searchParams.toString()}`
      );

      return {
        data: response.data.map((item: SortingProduct, idx: number) => ({
          ...item,
          _id: item.id,
          sortNumber: item.sortNumber ?? idx + 1,
        })),
        pagination: response.pagination,
      };
    },
    retry: false,
    retryOnMount: false,
    refetchInterval: false,
    cacheTime: 0,
  });

  const { mutate: saveSorting, isLoading: isSaving } = useMutation({
    mutationKey: 'saveSorting',
    mutationFn: async (items: SortingProductSavePayload[]) =>
      RequestAPI.patch(`${API_URL}/batch`, {
        batch: items,
      }),
    onSuccess: async () => {
      productSortingFormMethods.reset(undefined, { keepValues: true });

      notify('catalogue.pages.categories.sortingProducts.successMessage', {
        type: 'success',
        autoHideDuration: SNACKBAR_AUTOHIDE_DURATION,
      });

      const {
        handleSubmit,
        formState: { isDirty, isValid },
      } = categoryFormMethods;

      if (isDirty && isValid) {
        await handleSubmit((values) => save?.({ ...record, ...values }))();
      }
    },
    onError: () => {
      notify(
        'catalogue.pages.categories.sortingProducts.errors.somethingWentWrong',
        {
          type: 'error',
          autoHideDuration: SNACKBAR_AUTOHIDE_DURATION,
        }
      );
    },
  });

  const categoryFormMethods = useFormContext<CoreCategory>();
  const productSortingFormMethods = useForm<FormValues>({
    values: { data },
  });

  const handleSave = (e: SyntheticEvent) => {
    e.preventDefault();

    const { data } = productSortingFormMethods.getValues();

    saveSorting(
      data.map((item) => ({
        id: `${item._id}`,
        sortNumber: item.sortNumber ?? 1,
      }))
    );
  };

  const handleDragEnd = () => {
    const { isDefaultSorting } = categoryFormMethods.getValues();

    if (isDefaultSorting) {
      categoryFormMethods.setValue('isDefaultSorting', false, {
        shouldTouch: true,
        shouldDirty: true,
      });
    }
  };

  const handleSetPerPage = (val: number) => {
    setPerPage(val);
    setPage(1);
  };

  return (
    <>
      <RowInfoBlock>
        <ResourceSwitcher
          name="isDefaultSorting"
          labelKey="catalogue.pages.categories.sortingProducts.isDefaultSorting"
        />
      </RowInfoBlock>
      <FormProvider {...productSortingFormMethods}>
        <ReorderResourceTable<FormValues>
          source="data"
          emptyResource={translate(
            'catalogue.pages.categories.sortingProducts.emptyResource'
          )}
          columns={COLUMNS}
          sortingField="sortNumber"
          loading={isLoading}
          loadingLabel="catalogue.pages.categories.sortingProducts.tableLoading"
          onDragEnd={handleDragEnd}
          sortFn={(rows) => sortBy(rows, 'sortNumber')}
        />

        <Toolbar sx={{ justifyContent: 'space-between', width: '100%' }}>
          <SaveButton
            disabled={
              !productSortingFormMethods.formState.isDirty ||
              isSaving ||
              isLoading
            }
            type="button"
            onClick={handleSave}
            label={translate(
              isSaving
                ? 'catalogue.pages.categories.sortingProducts.saving'
                : 'catalogue.pages.categories.sortingProducts.saveButton'
            )}
          />

          <Pagination
            rowsPerPageOptions={PER_PAGE_ITEMS}
            perPage={perPage}
            setPage={setPage}
            setPerPage={handleSetPerPage}
            page={page}
            total={total}
          />
        </Toolbar>
      </FormProvider>
    </>
  );
};

export default SecondLvlCategoryProducts;
