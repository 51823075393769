import { UpdatePlaceholderStyle } from './interfaces';

export const getDroppableElement = (draggableId: string): Nullable<Element> => {
  const queryAttr = 'data-rbd-drag-handle-draggable-id';
  const domQuery = `[${queryAttr}='${draggableId}']`;

  return document.querySelector(domQuery);
};

export const getPlaceholderElement = (): Nullable<HTMLTableRowElement> => {
  const placeholderQueryAttr = 'data-rbd-placeholder-context-id';

  return document.querySelector(`[${placeholderQueryAttr}='0']`);
};

export const updatePlaceholderStyles = ({
  clientHeight,
  clientWidth,
  sourceIndex,
  children,
}: UpdatePlaceholderStyle): void => {
  const placeholderElement = getPlaceholderElement();

  if (!placeholderElement) {
    return;
  }

  for (const cell of children.map((item) => Array.from(item.children)).flat()) {
    (cell as HTMLTableCellElement).style.borderBottom = '1px solid transparent';
  }

  const clientY = children
    .slice(0, sourceIndex)
    .reduce((total, curr) => total + curr.clientHeight, 0);

  placeholderElement.style.height = `${clientHeight}px`;
  placeholderElement.style.width = `${clientWidth}px`;
  placeholderElement.style.top = `${clientY}px`;
  placeholderElement.style.left = '0';
};

export const returnBackCellBorders = (children: Element[]): void => {
  for (const child of children
    .map((item) => Array.from(item.children))
    .flat()) {
    (child as HTMLTableCellElement).style.borderBottom =
      '1px solid rgba(81, 81, 81, 1)';
  }
};
