import styled from 'styled-components';
import { Flex } from '@UI';

export const orderStatusColors: Record<
  CoreDCOrderStatus | CoreDCPickingOrdersStatus | CoreDCShipmentsStatus,
  string
> = {
  canceled: '#555',
  created: '#363d47',
  finished: '#013220',
  in_progress: '#3ea13e',
  not_started: '#363d47',
  picking: '#3ea13e',
  loading: '#007cad',
  processing: '#3ea13e',
  ready_to_ship: '#787112',
  shipped: '#013220',
};

export const StatusBadgeStyled = styled(Flex)<{
  orderStatus: CoreDCOrderStatus;
}>`
  padding: 6px 6px 4px;
  background-color: ${({ orderStatus }) => orderStatusColors[orderStatus]};
  border-radius: 6px;
  line-height: 1;
`;
