import styled from 'styled-components';

import { orderStatusColors } from '@Plugins/DistributionCenter/resources/share/StatusBadgeResource/styles';

export const StatusBadgeStyled = styled.span<{
  orderStatus: CoreDCOrderStatus;
}>`
  padding: 6px 6px 4px;
  background-color: ${({ orderStatus }) => orderStatusColors[orderStatus]};
  border-radius: 6px;
  line-height: 1;
`;
