import { Flex } from '@UI';
import { CircularProgress } from '@mui/material';
import { LoaderProps } from './interface';

export const Loader = ({
  fullHeight = true,
  minWrHeight,
  size,
}: LoaderProps) => {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      data-testid="selected-service-loading"
      minWidth={size ?? 200}
      minHeight={minWrHeight}
      fullHeight={fullHeight}
      flexGrow={1}
    >
      <CircularProgress size={size} />
    </Flex>
  );
};
