import { TopToolbar, useTranslate } from 'react-admin';
import { Button } from '@mui/material';
import { Add } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import { RequestExportFileButton } from '@Plugins/Catalogue/resources/Offers/components/RequestExportFileButton';
import { accessPermission } from '@Helpers';
import { UpdateListByFileButtonV2 } from '@UI';
import { ResourceRoutes } from '@Plugins/resourceRoutes';
import { offersApiUrl } from '@Plugins/Catalogue/resources/Offers/constants';

const ListActions = () => {
  const navigate = useNavigate();
  const translate = useTranslate();

  return (
    <>
      <TopToolbar>
        <RequestExportFileButton />

        {accessPermission.checkPermission('Catalogue', 'C') && (
          <UpdateListByFileButtonV2
            successMessage="catalogue.pages.offers.messages.successImport"
            errorMessage="catalogue.pages.offers.errors.errorImport"
            urlOfXLSXExample={`${offersApiUrl}/import/example`}
            maxSize={MAX_IMPORT_FILE_SIZE}
            maxFileRows={2000}
          />
        )}

        <Button
          variant="text"
          color="primary"
          size="small"
          onClick={() => navigate(`${ResourceRoutes.offer.routePath}/create`)}
          startIcon={<Add />}
        >
          {translate('catalogue.pages.offers.labels.create')}
        </Button>
      </TopToolbar>
    </>
  );
};

const MAX_IMPORT_FILE_SIZE = 2 * 1048576;

export default ListActions;
