import Inbox from '@mui/icons-material/Inbox';
import { Typography, Button } from '@mui/material';
import { useTranslate } from 'react-admin';

import { LinkedEmptyProps } from './interfaces';
import { EmptyClasses } from './constants';
import { Root } from './styled';

const LinkedEmpty = ({
  message,
  invite,
  action,
  onClick,
}: LinkedEmptyProps) => {
  const translate = useTranslate();

  return (
    <Root>
      <div className={EmptyClasses.message}>
        <Inbox className={EmptyClasses.icon} />
        <Typography variant="h4" paragraph>
          {translate(message)}
        </Typography>
        <Typography variant="body1">{translate(invite)}</Typography>
      </div>
      <div className={EmptyClasses.toolbar}>
        <Button variant="contained" color="primary" onClick={onClick}>
          {translate(action)}
        </Button>
      </div>
    </Root>
  );
};

export default LinkedEmpty;
