import {
  Datagrid,
  SimpleList,
  useList,
  ListContextProvider,
} from 'react-admin';
import { OFFER_LIST_IN_PRODUCT_COLUMNS } from '@Plugins/Catalogue/resources/Products/constants';
import { ComponentFactory } from '@Widgets/ResourceList/ComponentFactory/ComponentFactory';
import { useIsMobile } from '@ROOT/hooks';
import { Identifier } from 'ra-core';
import { ResourceRoutes } from '@Plugins/resourceRoutes';

export interface OffersViewTableProps {
  offers: CoreOffer[];
}

const OffersViewTable = (props: OffersViewTableProps) => {
  const { offers } = props;

  const listContextValue = useList({ data: offers });
  const isMobile = useIsMobile();

  const onRowClickHandler = (id: Identifier) =>
    `${ResourceRoutes.offer.routePath}/${id}`;

  return (
    <ListContextProvider value={listContextValue}>
      {isMobile ? (
        <SimpleList
          sx={{ padding: 0 }}
          primaryText={(record) => `ID ${record.id} - ` + record.name}
          secondaryText={(record) => record.code}
          linkType={(record, id) => onRowClickHandler(id)}
        />
      ) : (
        <Datagrid
          sx={{ width: '100%' }}
          rowClick={onRowClickHandler}
          bulkActionButtons={false}
        >
          {OFFER_LIST_IN_PRODUCT_COLUMNS?.map((column) => {
            const sortable = column.sortable ?? true;

            return (
              <ComponentFactory
                sortBy={sortable ? column.source : undefined}
                key={column.source}
                column={column}
                label={column.label}
              />
            );
          })}
        </Datagrid>
      )}
    </ListContextProvider>
  );
};

export default OffersViewTable;
