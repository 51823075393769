import { BaseSyntheticEvent } from 'react';

export const handleInput = (e: BaseSyntheticEvent) => {
  if (e.target.value.includes('.')) {
    const digitsAfterComma =
      parseInt(
        window.localStorage.getItem('RaStore.digitsAfterComma') || '2',
        10
      ) + 1;

    e.target.value = e.target.value.substring(
      0,
      e.target.value.indexOf('.') + digitsAfterComma
    );
  }
};
