export interface NormalizedCategoryFilters
  extends Omit<CoreCategory, 'filters'> {
  filters: {
    filterId: string;
    sorting: number;
  }[];
}

export const normalizeCategoryFilters = (
  category: CoreCategory
): NormalizedCategoryFilters => ({
  ...category,
  filters: category.filters?.map((item) => ({
    filterId: item.filterId,
    sorting: Number(item.sorting),
  })),
});
