import { useMemo } from 'react';
import { useGetList, useLocaleState } from 'react-admin';

import { ResourceRoutes } from '@Plugins/resourceRoutes';
import { PER_PAGE_VALUE } from '@Widgets/ResourceNestedList/constants';
import { RTL_SUPPORT_LANGUAGES } from '@ROOT/layout/themeProvider';

export interface SimpleParentCategory {
  id: number | string;
  name: string;
}

export const useParentCategoryList = (
  categoryId?: number | string
): SimpleParentCategory[] => {
  const { data: categoriesAPI = [] } = useGetList(
    ResourceRoutes.catalog.resourcePath,
    {
      pagination: {
        page: 1,
        perPage: PER_PAGE_VALUE,
      },
      sort: {
        field: 'sorting',
        order: 'ASC',
      },
    }
  );
  const [locale] = useLocaleState();
  const isRTL = RTL_SUPPORT_LANGUAGES.includes(locale);

  return useMemo(() => {
    if (!categoriesAPI.length) {
      return [];
    }

    const firstLvl = categoriesAPI.filter((item) => {
      if (categoryId !== undefined) {
        return !item.parentId && item.id !== categoryId;
      }

      return !item.parentId;
    });

    return firstLvl
      .map(({ id, name, nameAr }) => {
        const secondLvl = categoriesAPI.filter((item) => {
          if (categoryId !== undefined) {
            return item.parentId === id && item.id !== categoryId;
          }

          return item.parentId === id;
        });
        const localizedParentName = isRTL && nameAr ? nameAr : name;

        const children = secondLvl.map((item) => {
          const localizedChildName =
            isRTL && item.nameAr ? item.nameAr : item.name;
          const directedName = isRTL
            ? `${localizedChildName} \\ ${localizedParentName}`
            : `${localizedParentName} / ${localizedChildName}`;

          return { id: item.id, name: directedName };
        });

        return [{ id, name: localizedParentName }, ...children];
      })
      .flat();
  }, [categoriesAPI]);
};
