import { ReactNode, useEffect, useState } from 'react';
import {
  useResourceContext,
  useUpdateMany,
  useNotify,
  useTranslate,
} from 'react-admin';
import { Button, IconButton, Snackbar } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import { FileImport, SUPPORTED_FORMATS } from '@UI';
import { UPDATE_LIST_BY_FILE_UPLOAD } from '@ROOT/constants';
import {
  DownloadButton,
  removeTrailingSlashes,
  useSetErrorToErrorsContext,
} from '@Helpers';

import { FileImportProps } from '../FileImport/interface';

export interface UpdateListByFileButtonProps {
  successMessage: string;
  errorMessage: string;
  maxFileRows?: FileImportProps['maxFileRows'];
  meta?: unknown;
  accept?: FileImportProps['accept'];
  maxSize?: FileImportProps['maxSize'];
  urlOfXLSXExample?: string;
  children?: ReactNode;
}

export const UpdateListByFileButtonV2 = ({
  accept = SUPPORTED_FORMATS.excel.join(','),
  errorMessage,
  maxSize = 512000,
  maxFileRows,
  meta = UPDATE_LIST_BY_FILE_UPLOAD,
  successMessage,
  urlOfXLSXExample,
  children,
}: UpdateListByFileButtonProps) => {
  const [updateMany, { isLoading, error, isSuccess, data }] = useUpdateMany();
  const resource = useResourceContext();
  const resourceNameTrimmed = removeTrailingSlashes(resource);
  const { errors, onErrorHandler } = useSetErrorToErrorsContext();
  const notify = useNotify();
  const translate = useTranslate();
  const navigate = useNavigate();

  const [isSuccessSnackbarOpen, setIsSuccessSnackbarOpen] =
    useState<boolean>(false);

  const onFileSelectHandler = async (file: File) => {
    await updateMany(resource, {
      data: file,
      meta: meta,
    });
  };

  const handleRedirectToOfferImports = () => {
    const searchParams = new URLSearchParams();
    const [recentImportId] = data || [];

    searchParams.append('tab', 'imports');

    if (recentImportId) {
      searchParams.append('recentImportId', recentImportId);
    }

    navigate(
      { pathname: '/offer-operations', search: searchParams.toString() },
      { replace: true }
    );
  };

  const handleSuccessSnackbarClose = () => {
    setIsSuccessSnackbarOpen(false);
  };

  useEffect(() => {
    if (isSuccess) {
      setIsSuccessSnackbarOpen(true);

      return;
    }

    if (error) {
      onErrorHandler(error);
    }
  }, [isSuccess, error]);

  useEffect(() => {
    const resultErrors = Object.values(errors).flat();

    if (resultErrors.length) {
      const errorsText = [translate(errorMessage), ...resultErrors].join('\n');

      notify(errorsText, {
        type: 'error',
        autoHideDuration: 10000,
        multiLine: true,
      });
    }
  }, [JSON.stringify(errors)]);

  const downloadButton = urlOfXLSXExample ? (
    <DownloadButton
      url={urlOfXLSXExample}
      filename={`${resourceNameTrimmed}_example`}
      extension="xlsx"
    />
  ) : null;

  const childComponents = children ?? downloadButton;

  return (
    <>
      <FileImport
        isLoading={isLoading}
        isSuccess={false}
        accept={accept}
        maxSize={maxSize}
        onFileSubmitted={onFileSelectHandler}
        maxFileRows={maxFileRows}
      >
        {childComponents}
      </FileImport>

      <Snackbar
        open={isSuccessSnackbarOpen}
        autoHideDuration={16000}
        onClose={handleSuccessSnackbarClose}
        message={translate(successMessage)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        ContentProps={{
          sx: {
            bgcolor: 'success.dark',
            color: 'white',
          },
        }}
        action={
          <>
            <Button
              color="info"
              size="small"
              onClick={handleRedirectToOfferImports}
              sx={{ color: 'white' }}
            >
              {translate('catalogue.pages.offerImports.labels.goToImports')}
            </Button>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleSuccessSnackbarClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
    </>
  );
};
