import { Link as MuiLink, TableCell } from '@mui/material';
import { DragIndicator } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { DateField } from 'react-admin';

import { Flex, Preview } from '@UI';
import { ReorderTableColumn } from '@UI/ReorderResourceTable';

import { SortingProduct } from './interfaces';
import { getProductUrlByType } from './utils';

export const SORTING_PRODUCTS_API_URL = '/catalog/v1/category-products-sorting';

export const PER_PAGE_ITEMS = [20, 50, 100, 200];

export const COLUMNS: ReorderTableColumn[] = [
  {
    key: 'number',
    label: 'catalogue.pages.categories.sortingProducts.columns.number',
    renderer: (row: SortingProduct, { index }) => (
      <TableCell>
        <Flex
          key={row.productId}
          asColumn={false}
          justifyContent="flex-start"
          alignItems="center"
          gap={2}
        >
          <DragIndicator />
          <span>{row.sortNumber ?? index + 1}</span>
        </Flex>
      </TableCell>
    ),
  },
  {
    key: 'id',
    label: 'catalogue.pages.categories.sortingProducts.columns.id',
    renderer: (row: SortingProduct, { navigate }) => {
      return (
        <TableCell
          style={{ cursor: 'pointer' }}
          onClick={() => navigate(getProductUrlByType(row))}
        >
          {row.productId}
        </TableCell>
      );
    },
  },
  {
    key: 'mainImage',
    label: 'catalogue.pages.categories.sortingProducts.columns.mainImage',
    renderer: (row: SortingProduct, { navigate }) => {
      if (!row.mainImage?.url) {
        return <TableCell />;
      }

      return (
        <TableCell
          style={{ cursor: 'pointer' }}
          onClick={() => navigate(getProductUrlByType(row))}
        >
          <Preview src={row.mainImage.url} />
        </TableCell>
      );
    },
  },
  {
    key: 'name',
    label: 'catalogue.pages.categories.sortingProducts.columns.name',
    renderer: (row: SortingProduct, { navigate }) => (
      <TableCell
        style={{ cursor: 'pointer' }}
        onClick={() => navigate(getProductUrlByType(row))}
      >
        {row.productName}
      </TableCell>
    ),
  },
  {
    key: 'status',
    label: 'catalogue.pages.categories.sortingProducts.columns.status',
    renderer: (row: SortingProduct, { navigate, translate }) => (
      <TableCell
        style={{ cursor: 'pointer' }}
        onClick={() => navigate(getProductUrlByType(row))}
      >
        {translate(`catalogue.pages.products.filters.statuses.${row.status}`)}
      </TableCell>
    ),
  },
  {
    key: 'categoryName',
    label: 'catalogue.pages.categories.sortingProducts.columns.categoryName',
    renderer: (row: SortingProduct, { navigate }) => {
      const link = `/catalog/${row.categoryId}`;

      return (
        <TableCell style={{ cursor: 'pointer' }} onClick={() => navigate(link)}>
          <MuiLink to={link} component={Link}>
            {row.categoryName}
          </MuiLink>
        </TableCell>
      );
    },
  },
  {
    key: 'addedAt',
    label: 'catalogue.pages.categories.sortingProducts.columns.addedAt',
    renderer: (row: SortingProduct, { navigate }) => (
      <TableCell
        style={{ cursor: 'pointer' }}
        onClick={() => navigate(getProductUrlByType(row))}
      >
        <DateField record={row} source="addedAt" showDate showTime />
      </TableCell>
    ),
  },
];
