import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  DialogTitle,
} from '@mui/material';
import { useTranslate } from 'react-admin';

import { ConfirmAdultPackageDialogProps } from './interfaces';

const ConfirmAdultPackageDialog = (props: ConfirmAdultPackageDialogProps) => {
  const {
    open,
    title = 'ra.message.delete_title',
    confirmBtnText = 'ra.action.confirm',
    onClose,
    children,
    onDecline,
    onConfirm,
    closeOnDecline = true,
  } = props;

  const translate = useTranslate();

  const handleDecline = () => {
    if (closeOnDecline) {
      onClose?.();
    }

    onDecline?.();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{translate(title)}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button onClick={onConfirm} color="primary" variant="contained">
          {translate(confirmBtnText)}
        </Button>
        <Button onClick={handleDecline} color="error" variant="text">
          {translate('ra.action.cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmAdultPackageDialog;
