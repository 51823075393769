import { Fragment, cloneElement, isValidElement } from 'react';
import { useTranslate } from 'react-admin';
import { Draggable } from 'react-beautiful-dnd';
import { TableBody, TableCell, TableCellProps, TableRow } from '@mui/material';
import { ArrayPath, FieldArrayWithId, FieldValues } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { ReorderTableBodyProps } from './interfaces';

const ReorderTableBody = <
  FormValues extends FieldValues,
  Source extends ArrayPath<FormValues> = ArrayPath<FormValues>,
  Rows extends Array<FieldArrayWithId<FormValues, Source>> = Array<
    FieldArrayWithId<FormValues, Source>
  >
>(
  props: ReorderTableBodyProps<FormValues, Source, Rows>
) => {
  const { rows, columns = [], draggingRowWidth, placeholder } = props;

  const navigate = useNavigate();
  const translate = useTranslate();

  return (
    <TableBody sx={{ position: 'relative' }}>
      {rows.map((row, index) => {
        const item = row;

        return (
          <Draggable
            key={item.id}
            draggableId={item.id.toString()}
            index={index}
          >
            {(draggableProvided, snapshot) => {
              return (
                <TableRow
                  ref={draggableProvided.innerRef}
                  {...draggableProvided.draggableProps}
                  {...draggableProvided.dragHandleProps}
                  className={snapshot.isDragging ? 'draggingRow' : undefined}
                  style={{
                    ...draggableProvided.draggableProps.style,
                    ...(snapshot.isDragging &&
                      draggingRowWidth && {
                        width: draggingRowWidth,
                        display: 'table',
                      }),
                  }}
                >
                  {columns.map((column) => {
                    const key = `${row.id}-${column.key}`;
                    const node = column.renderer(row, {
                      index,
                      navigate,
                      translate,
                    });

                    if (['string', 'number'].includes(typeof node)) {
                      return (
                        <TableCell
                          sx={{
                            borderBottom: snapshot.isDragging
                              ? 'none'
                              : undefined,
                          }}
                          key={key}
                        >
                          {node}
                        </TableCell>
                      );
                    }

                    return isValidElement<{ sx?: TableCellProps['sx'] }>(
                      node
                    ) ? (
                      cloneElement(node, {
                        key,
                        sx: {
                          borderBottom: snapshot.isDragging
                            ? 'none'
                            : undefined,
                        },
                      })
                    ) : (
                      <Fragment key={key}>{node}</Fragment>
                    );
                  })}
                </TableRow>
              );
            }}
          </Draggable>
        );
      })}

      {placeholder}
    </TableBody>
  );
};

export default ReorderTableBody;
