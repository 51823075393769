import { styled, Theme } from '@mui/system';
import { Flex } from '@UI';
import { StatusBadgeResourceProps } from './interface';

const pickingOrderStatusColors = (
  theme: Theme
): Record<CorePickingOrderStatus, string> => ({
  created: theme.palette.grey['300'],
  in_progress: theme.palette.success.main,
  packing: theme.palette.success.main,
  assigned: theme.palette.success.main,
  finished: theme.palette.grey['800'],
  canceled: theme.palette.grey['800'],
});

export const StatusBadgeStyled = styled(Flex)<StatusBadgeResourceProps>(
  ({ theme, status }) => ({
    color:
      status !== 'created'
        ? theme.palette.primary.contrastText
        : theme.palette.grey['800'],
    padding: 4,
    backgroundColor: pickingOrderStatusColors(theme)[status],
    borderRadius: 8,
  })
);
