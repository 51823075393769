import { SortingProduct } from './interfaces';
import { ResourceRoutes } from '@Plugins/resourceRoutes';

export const getProductUrlByType = (product: SortingProduct): string => {
  const url =
    product.productType === 'bundle'
      ? ResourceRoutes.bundles.routePath
      : ResourceRoutes.product.routePath;

  return `${url}/${product.productId}`;
};
