import {
  SaveButton,
  Toolbar,
  useGetList,
  Pagination,
  useTranslate,
  useNotify,
  maxValue,
  number,
  minValue,
  maxLength,
  useRefresh,
} from 'react-admin';
import { useMutation } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { MouseEvent, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { TableCell } from '@mui/material';
import { DragIndicator } from '@mui/icons-material';

import { ResourceRoutes } from '@Plugins/resourceRoutes';
import { Flex } from '@UI';
import { RequestAPI } from '@RestApi';

import { ReorderResourceTable } from '@UI/ReorderResourceTable';

import { PickingPriorityFilters } from '../../components/PickingPriorityFilters';
import { PickingPriorityEditingTableCell } from '../../components/PickingPriorityEditingTableCell';
import { PickingPriorityTableCell } from '../../components/PickingPriorityEditingTableCell/styled';
import { WMS_PICKING_PRIORITY_LIST_API } from '../../constants';

import { PER_PAGE_ITEMS } from './constants';
import {
  PickingPriorityFormValues,
  PickingPriorityRemoteItem,
  CategoryMutationData,
} from './interfaces';
import { mutatePickingPriority, mapDataToArrayField } from './utils';
import { isInteger } from '@Helpers';

const PickingPriority = () => {
  const translate = useTranslate();
  const notify = useNotify();
  const [searchParams, setSearchParams] = useSearchParams();

  const [isSaving, setIsSaving] = useState<boolean>(false);

  const page = Number(searchParams.get('page') ?? 1);
  const perPage = Number(searchParams.get('perPage') ?? PER_PAGE_ITEMS[0]);
  const search = searchParams.get('search');
  const sorting = searchParams.get('sorting');

  const {
    data = [],
    total,
    refetch,
    isLoading,
  } = useGetList<PickingPriorityRemoteItem>(
    ResourceRoutes.wmsPickingPriority.resourcePath,
    {
      filter: search ? { search: search.trim() } : undefined,
      sort: {
        field: 'pickingPriority',
        order: sorting ? sorting.toUpperCase() : 'ASC',
      },
      pagination: {
        page,
        perPage,
      },
    },
    {
      queryKey: 'listKey',
      onSuccess: (response) => {
        const { data } = response;

        formMethods.reset({ data: data.map(mapDataToArrayField) });
      },
      cacheTime: 0,
      retry: false,
      refetchOnMount: false,
      retryOnMount: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
    }
  );

  const refresh = useRefresh();
  const { mutate: updatePickingPriority } = useMutation({
    mutationKey: 'updatePriority',
    mutationFn: async (data: CategoryMutationData) =>
      RequestAPI.patch(WMS_PICKING_PRIORITY_LIST_API, data),
    onSuccess: () => {
      refresh();
    },
    onError: (error) => {
      // TODO: Handle error
      // eslint-disable-next-line
      console.log(error);
    },
  });

  const pickingCodeSaveHandlerFactory =
    (item: PickingPriorityRemoteItem) =>
    (value: string): void => {
      updatePickingPriority({
        batch: [
          {
            id: item.categoryId,
            pickingPriority: item.pickingPriority,
            pickingCode: value.trim(),
          },
        ],
      });
    };

  const pickingPrioritySaveHandlerFactory =
    (item: PickingPriorityRemoteItem) =>
    (value: string): void => {
      if (!value) {
        return;
      }

      const numericVal = Number(value);

      if (isNaN(numericVal) || numericVal <= 0) {
        return;
      }

      updatePickingPriority({
        category: {
          id: item.categoryId,
          pickingPriority: numericVal,
        },
      });
    };

  const formMethods = useForm<PickingPriorityFormValues>({
    values: { data: data.map(mapDataToArrayField) },
  });

  const setPage = (page: number) => {
    searchParams.set('page', `${page}`);
    setSearchParams(searchParams);
  };

  const setPerPage = (val: number) => {
    searchParams.set('perPage', `${val}`);
    setSearchParams(searchParams);
  };

  const handleSave = async (e: MouseEvent) => {
    e.preventDefault();

    const { data } = formMethods.getValues();

    setIsSaving(true);

    try {
      await mutatePickingPriority(data);
      notify('wms-picking.pages.picking-priority.labels.successfullyUpdated', {
        type: 'success',
      });
    } catch (e) {
      notify('wms-picking.pages.picking-priority.labels.updateFailed', {
        type: 'error',
      });
    } finally {
      setIsSaving(false);
    }

    await refetch();
  };

  const columns = [
    {
      key: 'name',
      label: 'wms-picking.pages.picking-priority.labels.categoryName',
      renderer: (row: PickingPriorityRemoteItem) => (
        <TableCell>
          <Flex
            asColumn={false}
            justifyContent="flex-start"
            alignItems="center"
            gap={2}
          >
            <DragIndicator color="disabled" />
            <span>{row.name}</span>
          </Flex>
        </TableCell>
      ),
    },
    {
      key: 'mainCategories',
      label: 'wms-picking.pages.picking-priority.labels.mainCategories',
      renderer: (row: PickingPriorityRemoteItem) => row.path,
    },
    {
      key: 'pickingCode',
      label: 'wms-picking.pages.picking-priority.labels.pickingCode',
      renderer: (row: PickingPriorityRemoteItem) => (
        <PickingPriorityEditingTableCell
          label="wms-picking.pages.picking-priority.labels.pickingCode"
          fieldValue={row.pickingCode ?? ''}
          onSave={pickingCodeSaveHandlerFactory(row)}
          disabled={isSaving}
          validators={pickingCodeValidators}
        />
      ),
      CellComponent: PickingPriorityTableCell,
    },
    {
      key: 'pickingPriority',
      label: 'wms-picking.pages.picking-priority.labels.pickingPriority',
      renderer: (row: PickingPriorityRemoteItem) => (
        <PickingPriorityEditingTableCell
          label="wms-picking.pages.picking-priority.labels.pickingPriority"
          fieldValue={`${row.pickingPriority ?? ''}`}
          onSave={pickingPrioritySaveHandlerFactory(row)}
          disabled={isSaving}
          type="number"
          validators={
            !total
              ? pickingPriorityValidators
              : [...pickingPriorityValidators, maxValue(total)]
          }
        />
      ),
      CellComponent: PickingPriorityTableCell,
    },
  ];

  return (
    <FormProvider {...formMethods}>
      <PickingPriorityFilters />

      <ReorderResourceTable<PickingPriorityFormValues>
        source="data"
        sortingField="pickingPriority"
        columns={columns}
        loading={isLoading}
        loadingLabel="wms-picking.pages.picking-priority.labels.tableLoading"
      />

      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <SaveButton
          disabled={!formMethods.formState.isDirty || isSaving || isLoading}
          type="button"
          onClick={handleSave}
          label={
            isSaving
              ? translate('wms-picking.pages.picking-priority.labels.saving')
              : undefined
          }
        />

        <Pagination
          rowsPerPageOptions={PER_PAGE_ITEMS}
          perPage={perPage}
          setPage={setPage}
          setPerPage={setPerPage}
          page={page}
          total={total}
        />
      </Toolbar>
    </FormProvider>
  );
};

const pickingPriorityValidators = [
  number('wms-picking.pages.picking-priority.errors.mustBeNumber'),
  isInteger(),
  minValue(
    1,
    'wms-picking.pages.picking-priority.errors.mustBeGreaterThenZero'
  ),
];

const pickingCodeValidators = [maxLength(10)];

export default PickingPriority;
