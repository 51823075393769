import { accessPermission } from "@Helpers";
import {
  DeliveryScheduleSlotFormFields,
  FormValueInstance
} from "@Widgets/DeliverySchedule/components/ScheduleIntervalForm/interfaces";

export const normalizeMaxFutureDate = (record?: FormValueInstance<DeliveryScheduleSlotFormFields>) => {
  if (!record) {
    return undefined;
  }

  const { max_future_date, ...rest } = record;

  return {
    ...rest,
    max_future_date: accessPermission.company?.slotMaxFutureDate ?? max_future_date ?? 14
  }
}
