import styled from 'styled-components';
import { ListItem } from '@mui/material';
import { RadioButtonGroupInput, CheckboxGroupInput } from 'react-admin';

export const RadioButtonGroupStyled = styled(RadioButtonGroupInput)<{
  $dir: 'ltr' | 'rtl';
}>`
  .MuiFormControlLabel-root {
    ${({ $dir }) =>
      $dir === 'ltr'
        ? `
          margin-left: -11px;
          margin-right: 16px;
        `
        : `
          margin-right: -11px;
          margin-left: 16px;
        `}
  }
`;
export const CheckboxGroupInputStyled = styled(CheckboxGroupInput)<{
  $dir: 'ltr' | 'rtl';
}>`
  .MuiFormControlLabel-root {
    ${({ $dir }) =>
      $dir === 'ltr'
        ? `
          margin-left: -11px;
          margin-right: 16px;
        `
        : `
          margin-right: -11px;
          margin-left: 16px;
        `}
  }
`;

export const ListItemStyled = styled(ListItem)<{ $dir: 'rtl' | 'ltr' }>`
  ${({ $dir }) => $dir === 'rtl' && 'text-align: right;'}
`;
