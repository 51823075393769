import { FieldValues, FieldValue } from 'react-hook-form';

import { barcodeValidator } from '@Helpers/RegExpValidator/RegExpValidator';

export const requiredOnActive =
  (message = 'ra.validation.required') =>
  (value: FieldValue<FieldValues>, values: FieldValues) => {
    const { status } = values;

    if (status === 'active' && !value) {
      return message;
    }
  };

export const barcode =
  (message = 'catalogue.pages.offers.errors.incorrectBarcode') =>
  (value: string) =>
    !value || value.split('\n').every((barcode) => barcodeValidator(barcode))
      ? undefined
      : message;
