import { useController } from 'react-hook-form';
import { useQuery } from 'react-query';
import {
  useTranslate,
  Confirm,
  Identifier,
  ValidationErrorMessageWithArgs,
} from 'react-admin';
import { useState } from 'react';
import { Alert, Button, Typography } from '@mui/material';
import { Link as LinkIcon } from '@mui/icons-material';

import { RequestAPI } from '@RestApi';
import { Loader, Flex } from '@UI';
import { productsApiUrl } from '@Plugins/Catalogue/resources/Products/constants';
import { LinkedEmpty } from '@Plugins/Catalogue/resources/Products/components/LinkedEmpty';

import { SelectProductModalContent } from '../SelectProductModalContent';

import ItemViewTable from './ItemViewTable';
import { LinkedProductProps } from './interfaces';

const LinkedProduct = (props: LinkedProductProps) => {
  const { validate } = props;
  const translate = useTranslate();
  const {
    field: productIdField,
    fieldState: { error: productIdFieldError },
  } = useController<CoreOffer, 'productId'>({
    name: 'productId',
    rules: validate
      ? {
          validate: (value, formValues) => {
            const validators = Array.isArray(validate) ? validate : [validate];

            for (const fn of validators) {
              const errorMessage = fn(value, formValues, props);

              if (errorMessage) {
                if (typeof errorMessage === 'object') {
                  const error = errorMessage as ValidationErrorMessageWithArgs;

                  return translate(error.message, error.args);
                }

                return translate(errorMessage);
              }
            }

            return undefined;
          },
        }
      : undefined,
  });

  const [selectedProduct, setSelectedProduct] =
    useState<Nullable<CoreProduct>>(null);
  const [isSelectProductModalOpen, setIsSelectProductModalOpen] =
    useState<boolean>(false);

  const { isLoading, data: remoteSelectedProduct } = useQuery({
    queryKey: ['productDetail', productIdField],
    queryFn: async (): Promise<Nullable<CoreProduct>> => {
      if (!productIdField.value) {
        return null;
      }

      const response = await RequestAPI.get(
        `${productsApiUrl}/${productIdField.value}`
      );

      return response.data ?? null;
    },
    cacheTime: 0,
    retry: false,
    refetchOnMount: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
  });

  const handleCloseProductSelectModal = () => {
    setIsSelectProductModalOpen(false);
    setSelectedProduct(null);
  };

  const onSelect = (selected: Record<number, CoreProduct>) => {
    const [firstSelectedProduct] = Object.values(selected);

    if (!firstSelectedProduct) {
      return;
    }

    setSelectedProduct(firstSelectedProduct);
  };

  const isRowSelectable = (id: Identifier) => remoteSelectedProduct?.id !== id;

  const onConfirm = () => {
    if (!selectedProduct) {
      return;
    }

    productIdField.onChange(selectedProduct.id);
    handleCloseProductSelectModal();
  };

  const handleOpenProductSelectionWindow = () => {
    setIsSelectProductModalOpen(true)
  };

  return (
    <>
      <Flex fullWidth alignItems="stretch" asColumn gap={3}>
        {isLoading && <Loader fullHeight={false} minWrHeight={180} />}
        {remoteSelectedProduct && (
          <>
            <Flex fullWidth asColumn={false} justifyContent="flex-end">
              <Button
                variant="contained"
                color="primary"
                startIcon={<LinkIcon />}
                onClick={handleOpenProductSelectionWindow}
              >
                {translate('catalogue.pages.offers.labels.linkProduct')}
              </Button>
            </Flex>
            <ItemViewTable product={remoteSelectedProduct} />
          </>
        )}
        {productIdFieldError && (
          <Alert severity="error" variant="outlined">
            <Typography>{productIdFieldError.message}</Typography>
          </Alert>
        )}
        {!isLoading && !remoteSelectedProduct && (
          <LinkedEmpty
            message="catalogue.pages.offers.messages.emptyLinkedProduct"
            invite="catalogue.pages.offers.messages.emptyLinkedProductInvite"
            action="catalogue.pages.offers.labels.linkProduct"
            onClick={handleOpenProductSelectionWindow}
          />
        )}
      </Flex>

      <Confirm
        title="catalogue.pages.offers.labels.linkProduct"
        isOpen={isSelectProductModalOpen}
        fullWidth
        maxWidth="xl"
        keepMounted={false}
        content={
          <SelectProductModalContent
            selectedProduct={selectedProduct}
            onSelect={onSelect}
            isRowSelectable={isRowSelectable}
          />
        }
        onConfirm={onConfirm}
        onClose={handleCloseProductSelectModal}
      />
    </>
  );
};

export default LinkedProduct;
