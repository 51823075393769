import { ResourceMoneyInputProps } from '../interface';
import { ResourceInputNumber } from '@UI';
import { MoneyFormatter } from '@Helpers';
import { handleInput } from './utils';

export const ResourceMoneyInput = (props: ResourceMoneyInputProps) => {
  return (
    <ResourceInputNumber
      {...props}
      prefixSymbol={MoneyFormatter.getCurrencySymbol()}
      InputProps={{
        onInput: handleInput,
      }}
    />
  );
};
