import { CheckboxProps } from '@mui/material';

export enum PackageDeliveryType {
  Grocery,
  Alcohol,
  Tobacco,
  Pharma,
  Chemical,
  Frozen,
  OwnKitchen,
}

export enum CommodityGroup {
  GROCERY = 1,
  ALCOHOL = 2,
  TOBACCO = 3,
  PHARMA = 4,
  CHEMICAL = 5,
  FROZEN = 6,
  OWN_KITCHEN = 7,
  CLOTHING = 8,
  SHOES = 9,
  ACCESSORIES = 10,
}

export enum PackageHandOverStatus {
  RejectedOrMissing,
  ConfirmHandOver,
}

export interface BagCategory {
  name: string;
  paidTotal: number;
}

export enum BagCategoryName {
  ADULT = 'adult',
  FROZEN = 'frozen',
  OTHER = 'other',
}

export interface BagTypeCategory {
  deliveryTypes: PackageDeliveryType[];
  commodityGroups: CommodityGroup[];
  actualRemoteType: PackageDeliveryType;
}

export interface FormCheckboxProps extends CheckboxProps {
  source: string;
}
