import { accessPermission } from '@Helpers';
import {
  Button,
  CircularProgress,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  LinearProgress,
} from '@mui/material';
import {
  useRecordContext,
  useTranslate,
  useRefresh,
  useNotify,
  useShowContext,
} from 'react-admin';
import { apiUrl } from './constants';
import { BasicModal } from '@Widgets/BasicModal/BasicModal';
import { ItemsListConfirmResult } from './interface';
import { RequestAPI } from '@RestApi';
import { AxiosError } from 'axios';
import { useState } from 'react';

import ItemsList from './ItemsList';
import { getNextStatus } from './utils';

export const ManualChangeStatus = () => {
  const translate = useTranslate();
  const { status, order_id } = useRecordContext<CoreOrder>();
  const refresh = useRefresh();
  const notify = useNotify();
  const [isModalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const { isLoading: isDetailsLoading } = useShowContext();

  const closeModal = () => setModalOpen(false);
  const closeConfirmModal = () => setIsConfirmModalOpen(false);
  const handleChangeStatus = () => {
    if (['wh_created', 'picking'].includes(status)) setIsConfirmModalOpen(true);
    else if (['in_delivery', 'ready_to_ship'].includes(status))
      setModalOpen(true);
    else changeStatus().catch(() => undefined);
  };

  const handleConfirmStatusChange = async () => {
    await changeStatus();
    closeConfirmModal();
  };

  const changeStatus = async (items?: ItemsListConfirmResult[]) => {
    closeModal();
    setIsLoading(true);

    const newStatus = getNextStatus(status);

    if (!newStatus) {
      notify(translate('orders.pages.errors.failedChangeStatus'), {
        type: 'error',
      });

      return;
    }

    try {
      await RequestAPI.patch(apiUrl, {
        orderId: order_id,
        status: newStatus,
        items,
      });

      notify('orders.pages.alerts.changedStatus', {
        type: 'success',
      });
    } catch (error) {
      const errorMessage = [
        translate('orders.pages.errors.failedChangeStatus'),
        ...((error as AxiosError<CoreRestApiErrorResponse>)?.response?.data
          ?.errors || []),
      ];

      notify(errorMessage.join('\n'), {
        type: 'error',
        multiLine: true,
      });
    } finally {
      setTimeout(() => {
        setIsLoading(false);
        refresh();
      }, 300);
    }
  };

  if (
    !accessPermission.company?.isManualStatusChangeEnabled ||
    (!accessPermission.isSuperadmin && !accessPermission.isAdmin) ||
    !accessPermission.company?.availableStatusesForManualChange.includes(status)
  ) {
    return <></>;
  }

  const btnTitle = translate(
    `${accessPermission.company?.manualStatusChangeButtonTranslatePath}.${status}`
  );

  return (
    <>
      <Button
        color="primary"
        size="large"
        variant="contained"
        sx={{ m: 2 }}
        onClick={handleChangeStatus}
        disabled={isLoading || isDetailsLoading}
        startIcon={
          isLoading || isDetailsLoading ? <CircularProgress size={16} /> : <></>
        }
      >
        {btnTitle}
      </Button>

      <BasicModal isOpen={isModalOpen} onClose={closeModal} width={600}>
        <ItemsList onConfirm={changeStatus} onCancel={closeModal} />
      </BasicModal>

      <Dialog
        open={isConfirmModalOpen}
        onClose={closeConfirmModal}
        keepMounted={false}
      >
        <DialogTitle>
          {translate('orders.pages.confirmManualStatusChange.title')}
        </DialogTitle>
        <DialogContent>
          <Typography variant="subtitle2">
            {translate('orders.pages.confirmManualStatusChange.content')}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="error"
            onClick={closeConfirmModal}
            disabled={isLoading}
          >
            {translate('orders.pages.actions.cancel')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleConfirmStatusChange}
            disabled={isLoading}
          >
            {translate('orders.pages.actions.confirm')}
          </Button>
        </DialogActions>
        {isLoading && <LinearProgress />}
      </Dialog>
    </>
  );
};
