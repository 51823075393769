import {
  CreateResult,
  DeleteResult,
  GetListResult,
  GetOneResult,
  UpdateResult,
} from 'react-admin';
import sortBy from 'lodash/sortBy';

import { CommonCrud, PluginResourceController } from '@PluginBase';
import { Icon } from '@UI';
import { ResourceItemPage } from '@Widgets/ResourceItemPage/ResourceItemPage';
import { MoneyFormatter, pointsToUnits, unitsToPoints } from '@Helpers';

import { ResourceRoutes } from '../../../resourceRoutes';

import uploadImages from '../share/helpers/uploadImages';
import { sanitizePayload } from '../share/helpers/sanitizePayload';

import { ListPage } from './ListPage';
import { ProductForm } from './ProductForm';
import { productsApiUrl } from './constants';

const productCrud = new CommonCrud(productsApiUrl, { isNewSorting: true });

const ProductController = new PluginResourceController({
  menuItem: {
    caption: {
      translationKey: 'catalogue.pages.products.caption',
    },
    route: ResourceRoutes.product.routePath,
    icon: <Icon type="product" />,
  },
  resourceRoute: {
    name: ResourceRoutes.product.resourcePath,
    list: ListPage,
    create: (
      <ResourceItemPage type="create" includeForm={false}>
        <ProductForm />
      </ResourceItemPage>
    ),
    edit: (
      <ResourceItemPage type="edit" includeForm={false}>
        <ProductForm />
      </ResourceItemPage>
    ),
  },
  dataProvider: {
    getList: (resource, params): Promise<GetListResult> =>
      productCrud.list(params),
    getOne: async (resource, params): Promise<GetOneResult> => {
      if (params.id === undefined || params.id === null)
        return { data: { id: null } };

      const result = await productCrud.getOne<{ data: CoreProduct }>(params);

      if (result) {
        const points = result?.data?.price || 0;

        result.data.price = pointsToUnits(points);
      }

      if (
        result?.data.discountPrice !== undefined &&
        result.data.discountPrice !== null
      ) {
        result.data.discountPrice = pointsToUnits(result.data.discountPrice);
      }

      if (result?.data?.offers?.length) {
        result.data.offers = sortBy(result.data.offers, 'sorting').map(
          (offer) => {
            if (offer.price !== undefined && offer.price !== null) {
              offer.price =
                MoneyFormatter.getCurrencySymbol() + pointsToUnits(offer.price);
            }

            if (
              offer.discountPrice !== undefined &&
              offer.discountPrice !== null
            ) {
              offer.discountPrice =
                MoneyFormatter.getCurrencySymbol() +
                pointsToUnits(offer.discountPrice);
            }

            return offer;
          }
        );
      }

      return {
        data: result?.data,
      };
    },
    delete: async (resource, params): Promise<DeleteResult> => {
      await productCrud.delete(params);

      return {
        data: params.previousData,
      };
    },
    create: async (resource, params): Promise<CreateResult> => {
      params.data.name = params.data.name?.trim();
      params.data.price = unitsToPoints(params.data.price);
      params.data.sorting = parseInt(params.data.sorting, 10);

      if (params.data.discountPrice) {
        params.data.discountPrice = unitsToPoints(params.data.discountPrice);
      } else {
        params.data.discountPrice = null;
      }

      const result = await productCrud.create<{ data: CoreProduct }>({
        ...params,
        data: sanitizePayload(params.data),
      });

      return {
        data: result?.data,
      };
    },
    update: async (resource, params): Promise<UpdateResult> => {
      const {
        offerProperties,
        name,
        sorting,
        offers,
        price,
        discountPrice,
        ...rest
      } = params.data;

      rest.offerProperties = await uploadImages(offerProperties);
      rest.name = name?.trim();
      rest.sorting = parseInt(sorting, 10);
      rest.offersIds = offers?.map((item: CoreOffer) => item.id) ?? [];
      rest.price = unitsToPoints(price);
      rest.discountPrice = discountPrice ? unitsToPoints(discountPrice) : null;

      await productCrud.update({
        ...params,
        data: sanitizePayload(rest),
      });

      return {
        data: params.data,
      };
    },
  },
});

export default ProductController;
