export const getNextStatus = (
  status: CoreOrderStatus
): CoreOrderStatus | undefined => {
  const nextMap: Partial<Record<CoreOrderStatus, CoreOrderStatus>> = {
    wh_created: 'picking',
    picking: 'ready_to_ship',
    ready_to_ship: 'in_delivery',
    in_delivery: 'delivered',
  };

  return nextMap[status];
};
