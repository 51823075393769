import { AllowedImageTypes } from '@Widgets/ImageGalleryWidget/interface';

export const API_URL = process.env.REACT_APP_API_ROOT
  ? process.env.REACT_APP_API_ROOT
  : window.location.origin.replace('frontend-cms-', 'api2-');

export const MB = 1024 * 1024;

export const ACCEPTED_IMAGE_TYPES: AllowedImageTypes = {
  sizeMB: 2,
  types: ['png', 'jpeg'],
};

export const DEFAULT_ITEMS_PER_PAGE = 25;

export const UPDATE_LIST_BY_FILE_UPLOAD = 'updateListByFileUpload';

export enum ERROR_CODES {
  UNPROCESSABLE_FILE_ERROR = 'UNPROCESSABLE_FILE_ERROR',
}

export const SNACKBAR_AUTOHIDE_DURATION = 10000;
