import { useMemo } from 'react';
import { useGetList, useLocaleState, useTranslate } from 'react-admin';

import { ResourceRoutes } from '@PluginManager/plugins/resourceRoutes';
import { PER_PAGE_VALUE } from '@Widgets/ResourceNestedList/constants';
import { RTL_SUPPORT_LANGUAGES } from '@ROOT/layout/themeProvider';

export type SimpleCategory = Record<
  number,
  { parentId: number | null; name: string }
>;

interface NestedCategoryData {
  names: string[];
  ids: number[];
}

interface CategoryList {
  id: number;
  name: string;
  ids: number[];
}

/**
 * A hook that requests and returns a memoised list of nested categories.
 */
export function useCategoryList(categoryId?: number | string): CategoryList[] {
  const { data: categoriesAPI = [] } = useGetList(
    ResourceRoutes.catalog.resourcePath,
    {
      pagination: {
        page: 1,
        perPage: PER_PAGE_VALUE,
      },
      sort: {
        field: 'sorting',
        order: 'ASC',
      },
    }
  );
  const [locale] = useLocaleState();
  const t = useTranslate();
  const isRTL = RTL_SUPPORT_LANGUAGES.includes(locale);

  const categories = categoriesAPI.reduce<SimpleCategory>(
    (obj, { id, parentId = null, name, nameAr }) => {
      const localizedName = isRTL && nameAr ? nameAr : name;

      return {
        ...obj,
        [id]: { parentId, name: localizedName },
      };
    },
    {}
  );

  return useMemo<CategoryList[]>(() => {
    if (!categoriesAPI.length) return [];

    const getNestedCategoryData = (id: number): NestedCategoryData => {
      if (!categories[id]) {
        return {
          names: [
            `!!!${t('catalogue.pages.categories.validation.noParent')}!!!`,
          ],
          ids: [id],
        };
      }

      const { parentId, name } = categories[id];

      if (parentId === undefined || parentId === null) {
        return {
          names: [name],
          ids: [id],
        };
      }

      const { names, ids } = getNestedCategoryData(parentId);

      return {
        names: [name, ...names],
        ids: [id, ...ids],
      };
    };

    const list = categoriesAPI.map(({ id, name, parentId }) => {
      let ids: number[] = [id];

      if (parentId !== undefined && parentId !== null) {
        const { names, ids: idList } = getNestedCategoryData(parentId);

        name = isRTL
          ? [name, ...names].join(' \\ ')
          : [...names.reverse(), name].join(' / ');
        ids = isRTL ? [id, ...idList] : [...idList.reverse(), id];
      }

      return {
        id,
        name,
        ids,
      };
    });

    if (categoryId !== undefined) {
      return list.filter(({ ids }) => !ids.includes(categoryId as number));
    }

    return list;
  }, [categoryId, JSON.stringify(categories)]);
}
