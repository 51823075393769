import styled from 'styled-components';
import { Alert } from '@mui/material';
import { RadioButtonGroupInput } from 'react-admin';

import { RowInfoBlock } from '@UI';

export const RadioButtonGroupInputStyled = styled(RadioButtonGroupInput)`
  .MuiFormGroup-row {
    margin-top: 6px;
    flex-wrap: nowrap;
    column-gap: 8px;
  }

  .MuiFormControlLabel-root {
    margin: 0;
    padding: 0;
    width: 100%;

    &:first-child {
      .MuiFormControlLabel-label {
        color: #08b375;
        border-color: #08b375;

        &:hover {
          background: rgba(8, 179, 117, 0.1);
        }
      }

      .Mui-checked + .MuiFormControlLabel-label {
        background: #08b375 !important;
        color: #fff;
      }
    }

    &:last-child {
      .MuiFormControlLabel-label {
        color: #f44436;
        border-color: #f44436;

        &:hover {
          background: rgba(244, 68, 54, 0.1);
        }
      }

      .Mui-checked + .MuiFormControlLabel-label {
        background: #f44436;
        color: #fff;
      }
    }
  }

  .MuiButtonBase-root {
    display: none;
  }

  .MuiFormControlLabel-label {
    margin: 0;
    font-size: 14px;
    width: 100%;
    border-radius: 8px;
    border: 1px solid transparent;
    text-align: center;
    padding: 10px;
    white-space: nowrap;
    transition: color 0.2s, background-color 0.2s;
  }
`;

export const HighlightingRowInfoBlock = styled(RowInfoBlock)<{
  $selected: boolean;
}>`
  border: 1px solid;
  border-color: ${({ $selected }) => ($selected ? '#08b375' : '#19222d')};
  transition: border 0.5s ease-out;
`;

export const StyledAlert = styled(Alert)`
  & .MuiAlert-icon {
    margin-right: initial;
    margin-inline-end: 12px;
  }
`;
