import sortBy from 'lodash/sortBy';
import { AdditionalFieldWithId } from '@Plugins/Catalogue/resources/share/Tabs/AdditionalProperties/PropertiesFields/interfaces';

export const isNil = (value: unknown) => value === undefined || value === null;

export const submitPropertyValueByType = (
  property: AdditionalFieldWithId
): CoreProductAdditionalField => {
  const { type, value, values, referenceId, ...rest } = property;

  switch (type) {
    case 'sizeList':
    case 'colorList': {
      if (!Array.isArray(values)) {
        return {
          ...rest,
          id: referenceId,
          value: values,
          values: [values],
        };
      }

      return {
        ...rest,
        id: referenceId,
        value: values?.[0] ?? '',
        values: values || [],
      };
    }
    case 'checkboxGroup': {
      return {
        ...rest,
        id: referenceId,
        value: values?.[0] ?? '',
        values: values,
      };
    }
    case 'tagList':
    case 'selector':
    case 'radioGroup': {
      if (!values) {
        return {
          ...rest,
          id: referenceId,
          value: '',
          values: [],
        };
      }

      if (!Array.isArray(values)) {
        return {
          ...rest,
          id: referenceId,
          value: values,
          values: [values],
        };
      }

      return {
        ...rest,
        id: referenceId,
        value: value,
        values: values,
      };
    }
    case 'number': {
      if (
        value === undefined ||
        value === null ||
        value === '' ||
        isNaN(Number(value))
      ) {
        return {
          ...rest,
          id: referenceId,
          value: '',
          values: [],
        };
      }

      return {
        ...rest,
        id: referenceId,
        value: value.toString(),
        values: [value.toString()],
      };
    }
    case 'badge':
    case 'toggle': {
      return {
        ...rest,
        id: referenceId,
        value: `${!!value}`,
        values: [`${!!value}`],
      };
    }

    default: {
      return {
        ...rest,
        id: referenceId,
        value: isNil(value) ? '' : value,
        values: !isNil(value) ? [`${value}`] : [],
      };
    }
  }
};

export const normalizePropertyValueByType = (
  property: CoreProductAdditionalField
): AdditionalFieldWithId => {
  const { type, value, id } = property;

  switch (type) {
    case 'toggle': {
      const preparedValue =
        typeof value !== 'boolean' ? value === 'true' : value;

      return {
        ...property,
        id: id.toString(),
        referenceId: id,
        value: preparedValue,
        values: [preparedValue],
      };
    }
    case 'number':
      return {
        ...property,
        id: id.toString(),
        referenceId: id,
        value: Number(value),
        values: [Number(value)],
      };
    default:
      return {
        ...property,
        id: id.toString(),
        referenceId: id,
      };
  }
};

export const normalizeProperties = (offer: CoreOffer): CoreOffer => {
  if (Array.isArray(offer.properties) && offer.properties.length > 0) {
    offer.properties = sortBy(
      offer.properties.map(normalizePropertyValueByType),
      'referenceId'
    ) as unknown as CoreProductAdditionalField[];
  }

  return offer;
};
