import { styled } from '@mui/material/styles';
import { PREFIX, EmptyClasses } from './constants';

export const Root = styled('span', {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  width: '100%',
  paddingBlock: theme.spacing(3),
  [`& .${EmptyClasses.message}`]: {
    textAlign: 'center',
    opacity: theme.palette.mode === 'light' ? 0.5 : 0.8,
    margin: '0 1em',
    color:
      theme.palette.mode === 'light' ? 'inherit' : theme.palette.text.primary,
  },

  [`& .${EmptyClasses.icon}`]: {
    width: '3em',
    height: '3em',
  },

  [`& .${EmptyClasses.toolbar}`]: {
    textAlign: 'center',
    marginTop: '2em',
  },
}));
