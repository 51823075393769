import {
  Datagrid,
  Identifier,
  ListContextProvider,
  useList,
} from 'react-admin';

import { PRODUCT_LIST_COLUMNS } from '@Plugins/Catalogue/resources/Products/constants';
import { ComponentFactory } from '@Widgets/ResourceList/ComponentFactory/ComponentFactory';
import { ResourceRoutes } from '@Plugins/resourceRoutes';

export interface ItemViewTableProps {
  product: CoreProduct;
}

const ItemViewTable = (props: ItemViewTableProps) => {
  const { product } = props;

  const listContextProps = useList({ data: [product] });

  const onRowClick = (id: Identifier) => {
    return `${ResourceRoutes.product.resourcePath}/${id}`;
  };

  return (
    <ListContextProvider value={listContextProps}>
      <Datagrid bulkActionButtons={false} width="100%" rowClick={onRowClick}>
        {PRODUCT_LIST_COLUMNS.map((column) => {
          const sortable = column.sortable ?? true;

          return (
            <ComponentFactory
              sortBy={sortable ? column.source : undefined}
              key={column.source}
              column={column}
              label={column.label}
            />
          );
        })}
      </Datagrid>
    </ListContextProvider>
  );
};

export default ItemViewTable;
