import {
  AutocompleteInput,
  Confirm,
  ConfirmProps,
  DateField,
  RecordContextProvider,
  required,
  TextInput,
  useDataProvider,
  useNotify,
  useRecordContext,
  useRefresh,
  useTranslate,
  useUpdate,
} from 'react-admin';
import { Flex, Loader } from '@UI';
import { Button, Tab, Tabs, Typography } from '@mui/material';
import React, { MouseEventHandler, SyntheticEvent, useState } from 'react';
import {
  CANCEL_REASON_CHOICES,
  CANCEL_REASONS,
  OrderTab,
  UnCancellableStatuses,
} from '../../constants';
import { StatusBadgeResource } from '../components/StatusBadgeResource/StatusBadgeResource';
import { ApiActionCall } from '../../routes';
import { General } from './Tabs/General';
import { OrderItems } from './Tabs/OrderItems';
import { Delivery } from './Tabs/Delivery';
import { Feeds } from './Tabs/Feeds';
import { Payments } from './Tabs/Payments/Payments';
import { Comments } from './Tabs/Comments';
import { ResourceRoutes } from '@Plugins/resourceRoutes';
import { ManualChangeStatus } from '../components/ManualChangeStatus/ManualChangeStatus';
import {
  FormProvider,
  useForm,
  useFormContext,
  useFormState,
} from 'react-hook-form';
import { maxLengthExt } from '@Helpers';

const reasonFieldName = 'reason';
const otherReasonFieldName = 'otherReason';

const ConfirmDialogContent = () => {
  const { setValue } = useFormContext();
  const [selectedReason, setSelectedReason] = useState<string | undefined>('');
  const isOtherSelected = selectedReason === CANCEL_REASONS.other;

  const onReasonDropdownChange = (val: string) => {
    setValue(reasonFieldName, val);
    setSelectedReason(val);
  };

  return (
    <Flex asColumn>
      <Flex>
        <AutocompleteInput
          label="orders.pages.actions.cancelOrder.selectReason"
          id={reasonFieldName}
          name={reasonFieldName}
          choices={CANCEL_REASON_CHOICES}
          translateChoice
          fullWidth
          validate={[required()]}
          onChange={onReasonDropdownChange}
        />
      </Flex>
      {isOtherSelected && (
        <Flex mt={1}>
          <RecordContextProvider value={{ otherReasonFieldName }}>
            <TextInput
              name={otherReasonFieldName}
              source={otherReasonFieldName}
              label="orders.pages.actions.cancelOrder.selectReason"
              validate={[required(), maxLengthExt(255)]}
              fullWidth
              multiline
            />
          </RecordContextProvider>
        </Flex>
      )}
    </Flex>
  );
};

interface ConfirmDialogProps {
  onClose: MouseEventHandler;
  onConfirm: (val?: string) => void;
  isOpen?: boolean;
}

const ConfirmDialog = ({
  isOpen = false,
  onConfirm,
  onClose,
}: ConfirmDialogProps) => {
  const { getValues, setValue } = useFormContext();
  const { isValid } = useFormState();

  const onDialogConfirm: ConfirmProps['onConfirm'] = (event) => {
    if (!isValid) {
      event.preventDefault();

      return false;
    }

    const reasonText: string | undefined = getValues(reasonFieldName);
    const resultText =
      reasonText === CANCEL_REASONS.other
        ? getValues(otherReasonFieldName)
        : reasonText;

    if (resultText) {
      onConfirm(resultText);
    }
  };

  const onDialogClose: MouseEventHandler = (event) => {
    setValue(reasonFieldName, '');
    onClose(event);
  };

  return (
    <Confirm
      isOpen={isOpen}
      title="orders.pages.actions.cancelOrder.dialog.caption"
      content={<ConfirmDialogContent />}
      onConfirm={onDialogConfirm}
      onClose={onDialogClose}
      fullWidth
    />
  );
};

export const OrderDetails = () => {
  const record = useRecordContext<CoreOrder>();
  const methods = useForm({
    defaultValues: record,
    mode: 'all',
  });
  const translate = useTranslate();
  const notify = useNotify();
  const [, { isLoading }] = useUpdate();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const [currentTab, setCurrentTab] = useState(0);
  const [isRemoveOrderDialogOpen, setIsRemoveOrderDialogOpen] = useState(false);
  const onChangeTabHandler = (event: SyntheticEvent, newValue: number) =>
    setCurrentTab(newValue);
  const onCancelOrderClickHandler = () => setIsRemoveOrderDialogOpen(true);
  const onCloseRemoveOrderDialogHandler = () =>
    setIsRemoveOrderDialogOpen(false);
  const onConfirmRemoveOrderDialogHandler = async (val?: string) => {
    try {
      setIsRemoveOrderDialogOpen(false);

      await dataProvider.update(ResourceRoutes.orders.resourcePath, {
        id: record?.id,
        meta: ApiActionCall.cancelOrder,
        previousData: record,
        data: {
          cancelReason: translate(val || ''),
        },
      });

      notify('orders.pages.actions.cancelOrder.successMessage', {
        type: 'success',
      });
      refresh();
    } catch (e) {
      notify('orders.pages.actions.cancelOrder.errorMessage', {
        type: 'error',
      });
    }
  };

  if (!record || isLoading) return <Loader />;

  return (
    <Flex asColumn maxWidth={500} width="95vw">
      <FormProvider {...methods}>
        <Flex
          padding={1}
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <Flex asColumn>
            <Typography variant="h5">{record.order_id}</Typography>

            <Typography variant="subtitle2">
              {`${translate('orders.pages.labels.createdAt')}: `}

              <DateField source="created_at" />
            </Typography>
          </Flex>

          <Flex asColumn>
            <StatusBadgeResource />

            <Flex mt={1}>
              <Button
                disabled={UnCancellableStatuses.includes(record.status)}
                size="small"
                variant="contained"
                color="error"
                onClick={onCancelOrderClickHandler}
              >
                {translate('orders.pages.actions.cancelOrder.caption')}
              </Button>
            </Flex>
          </Flex>
        </Flex>

        <Tabs
          value={currentTab}
          onChange={onChangeTabHandler}
          variant="scrollable"
        >
          <Tab label={translate('orders.pages.tabs.general')} />

          <Tab label={translate('orders.pages.tabs.delivery')} />

          <Tab label={translate('orders.pages.tabs.orderItems')} />

          <Tab label={translate('orders.pages.tabs.feed')} />

          <Tab label={translate('orders.pages.tabs.payments')} />

          <Tab label={translate('orders.pages.tabs.notes')} />
        </Tabs>

        <Flex asColumn padding={1}>
          {currentTab === OrderTab.General && (
            <>
              <General />

              <ManualChangeStatus />
            </>
          )}

          {currentTab === OrderTab.Delivery && <Delivery />}

          {currentTab === OrderTab.OrderItems && <OrderItems />}

          {currentTab === OrderTab.Feed && <Feeds />}

          {currentTab === OrderTab.Payments && record?.payment_id && (
            <Payments />
          )}

          {currentTab === OrderTab.Notes && <Comments />}
        </Flex>

        <ConfirmDialog
          isOpen={isRemoveOrderDialogOpen}
          onConfirm={onConfirmRemoveOrderDialogHandler}
          onClose={onCloseRemoveOrderDialogHandler}
        />
      </FormProvider>
    </Flex>
  );
};
