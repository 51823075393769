import { useResourceContext, useTranslate } from 'react-admin';

import { OFFER_IMPORT_LIST_COLUMNS } from '@Plugins/Catalogue/resources/share/constants';
import { ResourceList } from '@Widgets/ResourceList/ResourceList';

import RecentOfferImportDetail from './RecentOfferImportDetail';
import { OfferImportsProps } from './interfaces';
import { OfferExportStatus } from '@Plugins/Catalogue/resources/OfferOperations/tabs/OfferExports/interfaces';

const OfferImports = (props: OfferImportsProps) => {
  const { onRowClick, rowRecord } = props;
  const resource = useResourceContext();
  const translate = useTranslate();

  return (
    <ResourceList<CoreOfferImport>
      columns={OFFER_IMPORT_LIST_COLUMNS}
      actions={false}
      showDeleteButton={false}
      getItemCardCaption={(record) =>
        translate('catalogue.pages.offerOperations.labels.mobileCardCaption', {
          id: record.id,
        })
      }
      defaultSort={{
        field: 'createdAt',
        order: 'DESC',
      }}
      rowClick={onRowClick}
      perPage={10}
      queryOptions={{
        refetchIntervalInBackground: false,
        refetchInterval: (data) => {
          const hasFilesInProgress = data?.data?.find((item) =>
            [OfferExportStatus.CREATED, OfferExportStatus.IN_PROGRESS].includes(
              item.status
            )
          );

          return hasFilesInProgress ? 5000 : false;
        },
        onSettled: (data) => {
          if (rowRecord) {
            const targetRow: CoreOfferImport | undefined = data?.data?.find(
              (item) => item.id === rowRecord.id
            );

            if (targetRow) {
              onRowClick?.(targetRow.id, resource, targetRow);
            }
          }
        },
      }}
    >
      <RecentOfferImportDetail onRowClick={onRowClick} />
    </ResourceList>
  );
};

export default OfferImports;
