import { TranslationNamespace } from '@Helpers';
import { PickingOrderStatus } from './interface';
import { TRANSLATION_SCHEMA } from './schemas/translationSchema';

export const pickingOrdersTranslationSchema = new TranslationNamespace<
  typeof TRANSLATION_SCHEMA
>('wms-picking.pages.picking-orders', TRANSLATION_SCHEMA);

export const PICKING_ORDER_STATUS_FILTER: SelectOptionTyped<PickingOrderStatus>[] =
  [
    {
      id: 'created',
      name: pickingOrdersTranslationSchema.lists.statuses.created,
    },
    {
      id: 'in_progress',
      name: pickingOrdersTranslationSchema.lists.statuses.in_progress,
    },
    {
      id: 'packing',
      name: pickingOrdersTranslationSchema.lists.statuses.packing,
    },
    {
      id: 'finished',
      name: pickingOrdersTranslationSchema.lists.statuses.finished,
    },
    {
      id: 'assigned',
      name: pickingOrdersTranslationSchema.lists.statuses.assigned,
    },
    {
      id: 'canceled',
      name: pickingOrdersTranslationSchema.lists.statuses.canceled,
    },
  ];
