import { useState, useRef } from 'react';
import { Empty, Loading, useTranslate } from 'react-admin';
import {
  DragDropContext,
  DragDropContextProps,
  Droppable,
} from 'react-beautiful-dnd';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableBody,
} from '@mui/material';
import {
  ArrayPath,
  FieldArrayWithId,
  FieldValues,
  useFieldArray,
  useFormContext,
} from 'react-hook-form';

import ReorderTableBody from './ReorderTableBody';
import { ReorderResourceTableProps } from './interfaces';
import {
  returnBackCellBorders,
  updatePlaceholderStyles,
  getDroppableElement,
} from './utils';

const ReorderResourceTable = <FormValues extends FieldValues>({
  source,
  emptyResource,
  columns = [],
  sortingField,
  loading = false,
  loadingLabel,
  TableHeadComponent = TableHead,
  onDragEnd,
  sortingStartIndex = 1,
  sortFn,
}: ReorderResourceTableProps<FormValues>) => {
  const translate = useTranslate();

  const { control } = useFormContext<FormValues>();

  const tableRef = useRef<Nullable<HTMLTableElement>>(null);

  const { fields: rows, replace } = useFieldArray<FormValues>({
    name: source,
    control,
  });

  const [draggingRowWidth, setDraggingRowWidth] =
    useState<Nullable<number>>(null);

  const handleDragEnd: DragDropContextProps['onDragEnd'] = (result) => {
    setDraggingRowWidth(null);
    const draggedDOM = getDroppableElement(result.draggableId);

    if (!draggedDOM) {
      return;
    }

    returnBackCellBorders([...(draggedDOM.parentNode?.children ?? [])]);

    const { source, destination } = result;

    const sourceTarget = rows[source.index];

    if (!sourceTarget || !destination) {
      return;
    }

    const destinationTarget = rows[destination.index];

    if (!destinationTarget) {
      return;
    }

    const [reorderedItem] = rows.splice(source.index, 1);

    rows.splice(destination.index, 0, reorderedItem);

    const scopedPriority =
      (rows.length &&
        Math.min(
          ...rows.map((row) => {
            const value =
              row[
                sortingField as keyof FieldArrayWithId<
                  FormValues,
                  ArrayPath<FormValues>
                >
              ];

            if (typeof value !== 'number') {
              return sortingStartIndex;
            }

            return value;
          })
        )) ||
      1;

    replace(
      rows.map((item, idx) => ({
        ...item,
        [sortingField]: scopedPriority + idx,
      }))
    );

    onDragEnd?.(
      { index: source.index, row: sourceTarget },
      { index: destination.index, row: destinationTarget },
      { rows, replace }
    );
  };

  const handleDragUpdate: DragDropContextProps['onDragUpdate'] = (result) => {
    if (!result.destination) {
      return;
    }

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    const draggedDOM = getDroppableElement(result.draggableId);

    if (!draggedDOM) {
      return;
    }

    const { clientHeight, clientWidth } = draggedDOM;

    const childrenArray = [...(draggedDOM.parentNode?.children ?? [])];
    const movedItem = childrenArray[sourceIndex];

    childrenArray.splice(sourceIndex, 1);

    const updatedArray = [
      ...childrenArray.slice(0, destinationIndex),
      movedItem,
      ...childrenArray.slice(destinationIndex + 1),
    ];

    updatePlaceholderStyles({
      clientWidth,
      clientHeight,
      sourceIndex,
      children: updatedArray,
    });
  };

  const handleOnDragStart: DragDropContextProps['onDragStart'] = (start) => {
    const draggingRow = document.querySelector(
      `[data-rbd-draggable-id="${start.draggableId}"]`
    );

    if (!draggingRow) {
      return;
    }

    setDraggingRowWidth((draggingRow as HTMLElement).offsetWidth);

    const { clientHeight, clientWidth } = draggingRow;

    updatePlaceholderStyles({
      clientHeight,
      clientWidth,
      sourceIndex: start.source.index,
      children: [...(draggingRow.parentNode?.children ?? [])],
    });
  };

  return (
    <DragDropContext
      onDragEnd={handleDragEnd}
      onDragStart={handleOnDragStart}
      onDragUpdate={handleDragUpdate}
    >
      <Droppable isDropDisabled={loading} droppableId="table">
        {(provided) => (
          <TableContainer
            component={Paper}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            <Table ref={tableRef}>
              <TableHeadComponent>
                <TableRow>
                  {columns.map(({ key, label, CellComponent }) => {
                    const text =
                      typeof label === 'string' ? translate(label) : label;

                    if (CellComponent) {
                      return <CellComponent key={key}>{text}</CellComponent>;
                    }

                    return <TableCell key={key}>{text}</TableCell>;
                  })}
                </TableRow>
              </TableHeadComponent>
              {loading && !rows.length && (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={columns.length}>
                      <Loading
                        loadingSecondary={loadingLabel}
                        sx={{
                          maxHeight: tableRef.current
                            ? tableRef.current.offsetHeight - 56
                            : 350,
                        }}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
              {!loading && !rows.length && (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={columns.length}>
                      <Empty hasCreate={false} resource={emptyResource} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
              {!loading && (
                <ReorderTableBody<FormValues>
                  rows={sortFn ? sortFn(rows) : rows}
                  columns={columns}
                  draggingRowWidth={draggingRowWidth}
                  placeholder={provided.placeholder}
                />
              )}
            </Table>
          </TableContainer>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default ReorderResourceTable;
