import { List, ListItemText, Typography, useTheme } from '@mui/material';
import { useTranslate } from 'react-admin';

import { ListItemStyled } from '../styled';

export const OrderNumberExampleHint = () => {
  const translate = useTranslate();
  const theme = useTheme();

  return (
    <>
      <Typography variant="subtitle1" sx={{ px: 2, pt: 1 }}>
        {translate('promotions.pages.promocodes.labels.examples')}
      </Typography>

      <List dense>
        {new Array(4).fill(null).map((_, idx) => (
          <ListItemStyled $dir={theme.direction} key={idx}>
            <ListItemText
              primary={translate(
                `promotions.pages.promocodes.help.orderNumberExamples.${idx}`
              )}
              secondary={translate(
                `promotions.pages.promocodes.help.orderNumberExamples.${idx}-secondary`
              )}
            />
          </ListItemStyled>
        ))}
      </List>
    </>
  );
};
