import { useRecordContext, useTranslate } from 'react-admin';
import { useState, useEffect, MouseEvent } from 'react';
import { ToggleButtonGroup, ToggleButton, Alert } from '@mui/material';
import { Link as LinkIcon } from '@mui/icons-material';

import { Flex, RowInfoBlock } from '@UI';
import { DateFormatter, formatPhone } from '@Helpers';

import { RefundType, PaymentMethod } from '../../../interface';
import {
  RefundableStatuses,
  UnRefundablePaymentStatuses,
  TemporaryDisabledPaymentStatuses,
  UnRefundableFullPaymentStatuses,
} from '../../../../constants';

import { PartialRefund } from './PartialRefund';
import { FullRefund } from './FullRefund';
import { StyledLink } from './styled';

export const Payments = () => {
  const record = useRecordContext<CoreOrder>();
  const translate = useTranslate();
  const [showRefundFormType, setShowRefundFormType] = useState<RefundType>();
  const [showRefundControls, setShowRefundControls] = useState(false);
  const paymentStatus = record.payment?.status || 'unknown';
  const paymentCreateAt = record.payment?.created_at;
  const buttonsDisabled =
    TemporaryDisabledPaymentStatuses.includes(paymentStatus);
  const onToggleRefundForm = (
    event: MouseEvent<HTMLElement>,
    value: RefundType
  ) => {
    setShowRefundFormType((type) => (value === type ? null : value));
  };

  useEffect(() => {
    setShowRefundControls(
      RefundableStatuses.includes(record.status) &&
        !UnRefundablePaymentStatuses.includes(paymentStatus)
    );
  }, [record.status, paymentStatus]);

  return (
    <Flex asColumn>
      {paymentCreateAt !== undefined && (
        <RowInfoBlock titleKey="orders.pages.labels.createdAt" isInline>
          {new DateFormatter(paymentCreateAt).relativeNow()}
        </RowInfoBlock>
      )}

      <RowInfoBlock titleKey="orders.pages.labels.status" isInline>
        {translate(`orders.pages.labels.payments.status.${paymentStatus}`)}
      </RowInfoBlock>

      {record.receipt_url && (
        <RowInfoBlock titleKey="orders.pages.labels.invoice" isInline>
          <StyledLink
            href={record.receipt_url}
            target="_blank"
            rel="noopener noreferer noreferrer"
          >
            <LinkIcon />{' '}
            <span>{translate('orders.pages.buttons.openInvoice')}</span>
          </StyledLink>
        </RowInfoBlock>
      )}

      {showRefundControls && (
        <ToggleButtonGroup
          value={showRefundFormType}
          exclusive
          onChange={onToggleRefundForm}
          fullWidth
          aria-label="text formatting"
        >
          {!UnRefundableFullPaymentStatuses.includes(paymentStatus) && (
            <ToggleButton
              value="full"
              aria-label="bold"
              disabled={buttonsDisabled}
            >
              {translate('orders.pages.labels.payments.refund.full')}
            </ToggleButton>
          )}

          <ToggleButton
            value="partial"
            aria-label="italic"
            disabled={buttonsDisabled}
          >
            {translate(
              'orders.pages.labels.payments.refund.partialRefund.caption'
            )}
          </ToggleButton>
        </ToggleButtonGroup>
      )}

      {showRefundControls && showRefundFormType === 'full' && <FullRefund />}

      {showRefundControls && showRefundFormType === 'partial' && <PartialRefund />}

      {paymentStatus === 'refunded' &&
        (record.payment_method as unknown as PaymentMethod) ===
          PaymentMethod.Cash && (
          <Alert severity="warning">
            <div
              dangerouslySetInnerHTML={{
                __html: translate('orders.pages.alerts.fullRefund', {
                  customer: `${record.recipient.full_name}, ${formatPhone(
                    record.recipient.phone
                  )}`,
                }),
              }}
            />
          </Alert>
        )}
    </Flex>
  );
};
